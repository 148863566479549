import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { assignAssistantToUser } from './assistantAction';

export const getUser = () => dispatch => {
    axios
        .get(process.env.REACT_APP_API_URL + '/users')
        .then(res => {
            dispatch({
                type: 'DETAILS_FORM_USER_API',
                payload: res.data.details
            })
        })
        .catch(err => {
            console.log("Failed to get user from API");
            NotificationManager.error("Failed to get current user")
        })
}

export const getUserFromSolution = () => dispatch => {
    axios
        .get(process.env.REACT_APP_API_URL_FOR_SOLUTION + '/api/organisations/')
        .then(res => {
            dispatch({
                type: 'DETAILS_FORM_USER_API',
                payload: res.data.details
            })
        })
        .catch(err => {
            console.log("Failed to get user from API");
            NotificationManager.error("Failed to get current user")
        })
}

export const updateUser = (user) => dispatch => {
    axios
        .put(process.env.REACT_APP_API_URL + '/users', user)
        .then(res => {
            dispatch({
                type: 'DETAILS_FORM_USER_API',
                payload: res.data.details
            })
            dispatch({
                type: 'USER_DETAILS_LOADER',
                payload: false
            })
            NotificationManager.success("User details updated successfully")
        })
        .catch(err => {
            dispatch({
                type: 'USER_DETAILS_LOADER',
                payload: false
            })
            NotificationManager.error("Failed to update user")
        })
}

export const getProfileUploadPresignedURL = (file) => {
    axios
        .post(process.env.REACT_APP_API_URL + "/organizations/upload", { 'fileName': file.name })
        .then(res => {
            let req = new XMLHttpRequest();
            req.open("PUT", res.data.details);
            req.onreadystatechange = function () {
                if (this.readyState === 4 && this.status === 200) {
                    NotificationManager.info("File Uploaded Successfully")
                }
            }
            req.send(file);
        })
        .catch(err => {
            NotificationManager.error("File Upload Failed")
        })
}

export const getUserInfoWithoutAuth = (document_id) => dispatch => {
    return axios
        .get(process.env.REACT_APP_API_URL + '/users/document/' + document_id)
        .then(res => { return res.data.details })
        .catch(err => {
            console.log(err)
        })
}

export const inviteUserToOrg = (payload, assistantId) => {
    return async (dispatch) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL_FOR_SOLUTION}/api/organisations/users`, payload);
            dispatch({ type: 'INVITE_USER_SUCCESS', payload: res.data });
            if (assistantId && res.data.login) {
                const assistantPayload = {
                    assistantId: assistantId,
                    userId: res.data.login,
                    assigned: true
                };
                await dispatch(assignAssistantToUser(assistantPayload));

                NotificationManager.success("User invited and assistant assigned successfully");
            } else {
                NotificationManager.success("User invited successfully, but no assistant assigned");
            }

        } catch (err) {
            dispatch({ type: 'INVITE_USER_LOADER', payload: false });
            NotificationManager.error(err.response.data.title);
        }
    };
};

export const getUserInfoFromSolution = () => dispatch => {
    return axios
        .get(process.env.REACT_APP_API_URL_FOR_SOLUTION + '/api/users')
        .then(res => { 
            dispatch({ type: 'CURRENT_USER_DATA_FROM_SOLUTION', payload: res.data });
            return res;
         })
        .catch(err => {
            console.log(err)
        })
}

