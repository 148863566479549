/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import Navbar from './Navbar';
import ProfileHeading from './ProfileHeading';
import DocumentsTable from './DocumentsTable';
import { getActivePlan } from '../actions/planAction';
import Footer from './Footer';
import { getUser } from '../actions/userAction';
import { getAllDocuments } from '../actions/documentAction';
import { addOrganization, getOrganizationByGetCall, getOrganizationOnboardStatusByGetCallSolution } from '../actions/organizationsAction';
import { useMediaQuery } from 'react-responsive';
import { Modal, Button } from 'react-bootstrap';
import '../styles/Documents.css';
import { userLogout } from '../actions/authAction';
import { NotificationManager } from 'react-notifications';

const Documents = () => {
  const [openModal, setOpenModal] = useState(false);
  const [orgName, setOrgName] = useState('');
  const [website, setWebsite] = useState('');
  const [urlError, setUrlError] = useState('');
  const dispatch = useDispatch();
  // const users = useSelector(state => state.userReducer);
  const organization = useSelector(state => state.organizationReducer);
  
  
  const plan = useSelector(state => state.planReducer);
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const history = useHistory();
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });

  useEffect(() => {
    if (Object.keys(organization).length === 0) {
      dispatch(getOrganizationByGetCall())
    }
    // Fetch active plan if not already loaded
    if (plan && Object.keys(plan).length === 0 && plan?.activePlan && Object.keys(plan.activePlan).length === 0) {
      dispatch(getActivePlan());
    }
    
    if (organization.onboard_status && (organization.onboard_status?.status === 'in_complete')) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [organization]);
  useEffect(() => {
    if (!organization.onboard_status){
      dispatch(getOrganizationOnboardStatusByGetCallSolution());
    }
    setIsDocumentLoading(true);
    dispatch(getAllDocuments())
      .then(() => {
        setIsDocumentLoading(false);
      })
      .catch(() => {
        setIsDocumentLoading(false);
      });
  }, []);

   const validURL = (str) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
      + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
      + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
      + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
      + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
      + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validURL(website.trim())) {
      setUrlError('Please enter valid company Website Link.');
      return;
    } else {
      setUrlError('');
    }

    const org = {
      "virtualAssistent": {
        "name": "Ashley",
        "description": "",
        "signature": "Business Development Executive",
        "phoneno": null,
        "emailid": `ashley@${orgName.trim()}.7ts-d.com`,
        "assistantType": "SALES",
        "imageUrl": "https://7targets.ai/images-for-7ts/avatar-2.svg",
        "wAppAssistantDailyWakeupTime": null,
        "wAppLoginData": null,
        "emailServiceId": null,
        "wAppsSearchAndAddLeads": null,
        "additionalInformationLine": "",
        "primaryOffering": "",
        "role": "",
        "icp": ""
      },
      "organisation": {
        "createdBy": null,
        "lastModifiedBy": null,
        "createdDate": null,
        "lastModifiedDate": null,
        "id": null,
        "name": orgName.trim(),
        "domain": orgName.trim(),
        "description": "",
        "users": null,
        "status": null,
        "sendEmailStatus": null,
        "url": website.trim() || '',
        "customerUpdatesUrl": null,
        "orgStats": null,
        "sesEnabled": false,
        "sendgridEnabled": false,
        "industry": null
      }
    };
    NotificationManager.info('Adding Organization details.')
    dispatch(addOrganization(org))
      .then(() => {
        setOpenModal(false);
      })
      .catch(() => {
      });
  };
  return (
    <React.Fragment>
      <Navbar />

      <div className={`d-sm-flex d-flex flex-column justify-content-center container-box ${openModal ? 'blur-background' : ''}`} style={{ paddingTop: "60px" }}>
        <ProfileHeading />
        <DocumentsTable isDocumentLoading={isDocumentLoading} openModal={e => setOpenModal(true)} />
      </div>
      {!isMobile && <Footer />}

      <Modal show={openModal} onHide={() => { setOpenModal(false) }} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>We need a few details from you</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="orgName" className="form-label">Organization Name <sup style={{ color: 'red' }}>*</sup></label>
              <input
                type="text"
                className="form-control"
                id="orgName"
                value={orgName}
                onChange={e => setOrgName(e.target.value)}
                placeholder="Your Organization Name"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="website" className="form-label">Company Website URL <sup style={{ color: 'red' }}>*</sup></label>
              <input
                type="text"
                className="form-control"
                id="website"
                value={website}
                placeholder="Company Website Link"
                onChange={e => setWebsite(e.target.value)}
                required
              />
              {urlError && <div className="text-danger mt-2">{urlError}</div>}
            </div>
            <div className='d-flex align-items-center justify-content-between'>
            <Button type="submit" variant="primary" style={{ backgroundColor: '#6f42c1' }}>Submit</Button>
            <button className='btn closeButton' type='button' onClick={() => dispatch(userLogout())}>
              Logout
            </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Documents;
