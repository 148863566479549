import axios from 'axios';
import { NotificationManager } from 'react-notifications';

export const exportAllViews = (documentId) => dispatch => {
    return axios
        .get(process.env.REACT_APP_API_URL + '/views/export/document/' + documentId, { responseType: 'blob' })
        .then(res => {
            const href = URL.createObjectURL(res.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'export.csv'); // or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
        .catch(err => {
            NotificationManager.error("Failed to export views of current user");
            throw err; // Propagate the error for handling in the component
        });
}

export const getAllViewsByDocumentId = (documentId) => dispatch => {
    return axios
    .get(process.env.REACT_APP_API_URL + '/views/document/' + documentId)
    .then(res => {
        dispatch({
            type: 'ADD_VIEWS',
            payload: {documentId : res.data.details}
        })
    })
    .catch(err => {
        NotificationManager.error("Failed to get analysis")
    })
}
