import isEmpty from "./is-empty";
import {
    validatePasswordFormationForForgotPassword, validateConfirmPasswordForForgotPassword, validateForgotPasswordCode
} from "../utils";

export const validateResetPasswordSubmit = data => {
    let errors = {};

    data.code = !isEmpty(data.code) ? data.code : "";
    data.newPassword = !isEmpty(data.newPassword) ? data.newPassword : "";
    data.confirmPassword = !isEmpty(data.confirmPassword) ? data.confirmPassword : "";

    validateForgotPasswordCode(errors, data, 'fpSubmitCode', 'code');
    validatePasswordFormationForForgotPassword(errors, data, 'fpSubmitPassword', 'newPassword');
    validateConfirmPasswordForForgotPassword(errors, data, 'confirmPassword', 'confirmPassword', 'newPassword');

    return {
        errors,
        isValid: isEmpty(errors)
    };
};
