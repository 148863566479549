import axios from 'axios';
import {NotificationManager} from 'react-notifications';

export const getNotificationPreferences = () => dispatch => {
    axios
    .get(process.env.REACT_APP_API_URL + '/notification-preferences')
    .then(res => {
        dispatch({
            type: 'DETAILS_FROM_NOTIFICATION_PREFERENCES_API',
            payload: res.data.details
        })
    })
    .catch(err => {
        console.log("Failed to get user from API");
        NotificationManager.error("Failed to get Notification Preference")
    })
}
export const updateNotificationPreferences= (notificationPreferences) => dispatch => {
    axios
    .put(process.env.REACT_APP_API_URL + '/notification-preferences', notificationPreferences)
    .then(res => {
        dispatch({
            type: 'DETAILS_FROM_NOTIFICATION_PREFERENCES_API',
            payload: res.data.details
        })
        dispatch({
            type: 'SAVING_NOTIFICATION_PREFERENCES',
            payload: {preference_loader: false}
        })
        NotificationManager.success("Notification Preferences updated successfully")
    })
    .catch(err => {
        NotificationManager.error("Failed to update Notification Preferences")
    })
}
