/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Navbar from './Navbar';
import '../styles/Plans.css';
import axios from 'axios';
import { getPlans, selectAndSavePlan } from '../actions/planAction';
import { useHistory } from 'react-router-dom';
import Include from '../images/check.png';
import NotInclude from '../images/cancel.png';
import { NotificationManager } from 'react-notifications';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { getActivePlan } from '../actions/planAction';
import { ThreeDots } from 'react-loader-spinner';
import { getOrganizationByGetCallSolution } from '../actions/organizationsAction';

const Plan = () => {
    const [currency, setCurrency] = useState('usd');
    const [loader, setLoader] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [userActivePlan, setUserActivePlan] = useState({});
    const [loadingConfirmButton, setLoadingConfirmButton] = useState(false);
    const [currentPlan, setCurrentPlan] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();
    const plans = useSelector(state => state.planReducer);
    const doculens_plans = useSelector(state => state.planReducer.doculens_plans);
    const user = useSelector(state => state.userReducer);
    const userPlanSave = useSelector(state => state.planReducer.userPlanSave);
    const orgDetailsFromSolution = useSelector(state => state.organizationReducer.orgDetailsFromSolution);

    const [userDetails, setUserDetails] = useState({
        businessName: '',
        city: '',
        country: '',
        gstNumber: '',
        stateName: '',
        streetAddress: '',
        zipCode: ''
    });

    useEffect(() => {
        dispatch({ type: 'DOCULENS_PLANS_LOADER', payload: true });
        dispatch(getPlans(currency));
    }, [currency]);
    useEffect(() => {
        if (orgDetailsFromSolution) {
            setUserDetails(prevDetails => ({
                ...prevDetails,
                businessName: orgDetailsFromSolution.name
            }));
        }
    }, [orgDetailsFromSolution]);

    useEffect(() => {
        setCurrentPlan(plans.activePlan);
    }, [plans])


    useEffect(() => {
        const fetchActivePlan = async () => {
            try {
                const { data: activePlan } = await dispatch(getActivePlan());
                if (activePlan.status === 'ACTIVE' && activePlan.name !== 'TRIAL') {
                    const { currency } = activePlan;
                    setCurrency(currency.toLowerCase());
                    setUserActivePlan(activePlan || {});
                }
            } catch (error) {
                console.error('Error fetching active plan:', error);
            }
        };
        dispatch(getOrganizationByGetCallSolution());

        fetchActivePlan();
    }, [dispatch]);


    useEffect(() => {
        setLoader(plans.plan_loader);
    }, [plans]);

    const formatPrice = (price) => {
        if (price >= 1000) {
            return (price / 1000).toFixed(price % 1000 === 0 ? 0 : 1) + 'K';
        }
        return price;
    };

    const onClickSubscribePlan = (plan) => {
        setSelectedPlan(plan);
        setShowModal(true);
    }

    const handleModalClose = () => setShowModal(false);

    const shimmerPricingCard = () => (
        <div className="col-lg-4 mb-4">
            <div className="card h-100 shadow-sm rounded-lg">
                <div className="card-body text-center shimmer-wrapper">
                    <h4 className="card-title font-weight-bold shimmer-pricing"></h4>
                    <h2 className="pricing font-weight-bold mb-3 shimmer-pricing"></h2>
                    <button className="btn btn-outline-primary btn-block mb-3 shimmer-pricing shimmer-pricing-button"></button>
                    <ul className="list-unstyled shimmer-featurelist">
                        <li className='shimmer-pricing' />
                        <li className='shimmer-pricing' />
                        <li className='shimmer-pricing' />
                    </ul>
                </div>
            </div>
        </div>
    );

    const pricingCards = () => (
        <div className="row d-flex justify-content-center align-items-center">
            {doculens_plans?.map((plan, index) => {
                return (
                    <div key={index} className="col-lg-4 mb-4">
                        <div className="card h-100 shadow-sm rounded-lg">
                            <div className="card-body text-center">
                                <h4 className="card-title font-weight-bold">{plan.name}</h4>
                                <h2 className="pricing font-weight-bold mb-3">
                                    {plan.currency === 'INR' ? '₹' : '$'}{formatPrice(plan.price)}
                                    <small className="text-muted payableText">/{plan.payable.toLowerCase()}</small>
                                </h2>

                                {userActivePlan?.externalId === plan.externalId ? (
                                    <div className='d-flex align-items-center justify-content-center' style={{ gap: '15px' }}><button
                                        className="btn subscribed-btn btn-block  d-flex align-items-center" style={{ gap: '5px', cursor: 'not-allowed' }}
                                    >
                                        Subscribed
                                    </button>
                                        <Button variant="outline-secondary" onClick={updatePaymentInfo}>Cancel Plan</Button>
                                    </div>
                                ) : (
                                    <button
                                        className="btn btn-outline-primary btn-block mb-3"
                                        onClick={() => onClickSubscribePlan(plan)}
                                    >
                                        Subscribe
                                    </button>
                                )}

                                <ul className="list-unstyled mt-3">
                                    {plan.features && plan.features.length > 0 ? (
                                        plan.features.map((feature, idx) => (
                                            <li key={idx}>
                                                <div className='d-flex align-items-center' style={{ gap: '1vw', textAlign: 'left' }}>
                                                    <img src={feature.limit === "true" ? Include : NotInclude} alt="feature-status" className='featureImg' />
                                                    <span className="feature-text" style={{ marginLeft: '0.5rem', verticalAlign: 'middle' }}>{feature.name}</span>
                                                </div>
                                            </li>
                                        ))
                                    ) : (
                                        <li>No additional features listed</li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );


    const options = useMemo(() => countryList().getData(), []);

    const handleCountryChange = (selectedOption) => {
        setUserDetails({ ...userDetails, country: selectedOption.label });
    };

    const onClickConfirm = async () => {
        setLoadingConfirmButton(true);
        try {
            const plan = { ...userDetails, ...selectedPlan };
            const userPlan = await dispatch(selectAndSavePlan(plan));
            if (userPlanSave) {
                subscribeBtnHandler(userPlan.data);
            }
        } catch (error) {
            setLoadingConfirmButton(false);
            console.error('Error during plan selection:', error);
            NotificationManager.error("There was an issue processing your plan selection.");
        }
    };
    useEffect(() => {
        const params = new URLSearchParams(history.location.search);
        const paymentStatus = (params.get('failure') || '');
        const subscribedPlanIdOnStripe = (params.get('session_id') || '');
        if (paymentStatus === '0' || subscribedPlanIdOnStripe) {
            if (subscribedPlanIdOnStripe) {
                NotificationManager.success('Subscription successful');
            }
            if (paymentStatus === '0') {
                NotificationManager.error('Subscription Failed', 'Please retry');
            }
            const { host } = window.location;
            const hostName = window.location.hostname;
            const newUrl = `${hostName === 'localhost' ? 'http://' : 'https://'}${hostName === 'localhost' ? host : hostName}/plan`;
            window.history.pushState('', '7Targets', newUrl);
        }
        // eslint-disable-next-line
    }, []);

    const subscribeBtnHandler = (userPlan) => {
        const { host } = window.location;
        const hostName = window.location.hostname;
        const email = user.email_id || '';
        axios.post(`${process.env.REACT_APP_API_URL_FOR_SOLUTION}/api/management/org/subscription/stripe/checkout/session/create`, {
            customerId: userPlan.stripeCustomerId,
            cancelUrl: `${hostName === 'localhost' ? 'http://' : 'https://'}${hostName === 'localhost' ? host : hostName}/plan?success=0`,
            planId: userPlan.id,
            planExternalId: userPlan.externalId,
            successUrl: `${hostName === 'localhost' ? 'http://' : 'https://'}${hostName === 'localhost' ? host : hostName}/plan`,
            customerEmail: email,
        }).then((res) => {
            setLoadingConfirmButton(false);
            if (res.data) {
                window.location.replace(res.data.url);
            }
        }).catch((err) => {
            NotificationManager.error("Please try again later")
        });
    };

    const updatePaymentInfo = () => {
        const { host } = window.location;
        const hostName = window.location.hostname;
        axios.post(`${process.env.REACT_APP_API_URL_FOR_SOLUTION}/api/management/org/subscription/stripe/session/create`, {
            customerId: userActivePlan.stripeCustomerId,
            returnUrl: `${hostName === 'localhost' ? 'http://' : 'https://'}${hostName === 'localhost' ? host : hostName}/plan`
        }).then((res) => {
            if (res.data) {
                window.location.replace(res.data.url);
            }
        }).catch((err) => {
            NotificationManager.error("Please try again later")
        });
    };

    const validateForm = () => {
        if (currency === 'inr') {
            return userDetails.businessName !== '' && userDetails.gstNumber !== '';
        } else {
            return userDetails.businessName !== '' && userDetails.city !== '' && userDetails.streetAddress !== '' && userDetails.stateName !== '' && userDetails.zipCode !== '' && userDetails.country !== '';
        }
    };
    const renderConfirmationModal = () => (
        <Modal show={showModal} onHide={handleModalClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="mb-3">
                        <label className="form-label">
                            Company Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Company name for invoice"
                            value={userDetails.businessName}
                            onChange={(e) => setUserDetails({ ...userDetails, businessName: e.target.value })}
                            required
                        />
                    </div>

                    {currency !== 'inr' && (
                        <>
                            <div className="mb-3">
                                <label className="form-label">
                                    Street Address <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Street Address"
                                    value={userDetails.streetAddress}
                                    onChange={(e) => setUserDetails({ ...userDetails, streetAddress: e.target.value })}
                                    required
                                />
                            </div>

                            <div className="mb-3 d-flex justify-content-between">
                                <div style={{ width: '32%' }}>
                                    <label className="form-label">
                                        City <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="City"
                                        value={userDetails.city}
                                        onChange={(e) => setUserDetails({ ...userDetails, city: e.target.value })}
                                        required
                                    />
                                </div>

                                <div style={{ width: '32%' }}>
                                    <label className="form-label">
                                        State <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="State"
                                        value={userDetails.stateName}
                                        onChange={(e) => setUserDetails({ ...userDetails, stateName: e.target.value })}
                                        required
                                    />
                                </div>

                                <div style={{ width: '32%' }}>
                                    <label className="form-label">
                                        Zipcode <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Zipcode"
                                        value={userDetails.zipCode}
                                        onChange={(e) => setUserDetails({ ...userDetails, zipCode: e.target.value })}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="mb-3">
                                <label className="form-label">
                                    Country <span style={{ color: 'red' }}>*</span>
                                </label>
                                <Select
                                    options={options}
                                    value={options.find(option => option.label === userDetails.country)}
                                    onChange={handleCountryChange}
                                    placeholder="Select Country..."
                                    required
                                />
                            </div>
                        </>
                    )}

                    {currency === 'inr' && (
                        <div className="mb-3">
                            <label className="form-label">
                                GST Number <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="GST Number"
                                value={userDetails.gstNumber}
                                onChange={(e) => setUserDetails({ ...userDetails, gstNumber: e.target.value })}
                                required
                            />
                        </div>
                    )}
                </form>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleModalClose} variant="outline-secondary">
                    Cancel
                </Button>
                <Button className="confirmPlanButton" onClick={onClickConfirm} disabled={!validateForm()}>
                    {loadingConfirmButton ? (
                        <div className="loader-container">
                            <ThreeDots
                                height="20"
                                width="20"
                                color="#fff"
                                ariaLabel="loading"
                            />
                        </div>
                    ) : (
                        'Confirm'
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    )

    return (
        <>
            <Navbar />
            <div className='d-sm-flex d-flex flex-column justify-content-center container-box' style={{ paddingTop: "60px" }}>
                <div className='container-fluid w-100'>
                    <div className='container mt-4'>
                        <h2 className="text-center mb-4">{currentPlan?.status === 'ACTIVE' && currentPlan?.name !== 'TRIAL' ? 'Active Plan' : 'Choose Your Plan'}</h2>
                        <div className="text-center d-flex justify-content-center align-items-center mb-4">
                            <div className='currency-toggle-container'>
                                <span className={`currency-option ${currency === 'usd' ? 'active' : ''}`} onClick={() => setCurrency('usd')} style={{ pointerEvents: (currentPlan?.status === 'ACTIVE' && currentPlan?.name !== 'TRIAL') ? 'none' : 'auto' }}>
                                    USD
                                </span>
                                <div className={`toggle-wrapper ${(currentPlan?.status === 'ACTIVE' && currentPlan?.name !== 'TRIAL') ? 'disabled' : ''}`} onClick={() => {
                                    if (!(currentPlan?.status === 'ACTIVE' && currentPlan?.name !== 'TRIAL')) {
                                        setCurrency(currency === 'usd' ? 'inr' : 'usd');
                                    }
                                }}>
                                    <div className={`toggle-slider ${currency === 'inr' ? 'toggle-right' : 'toggle-left'}`}></div>
                                </div>
                                <span className={`currency-option ${currency === 'inr' ? 'active' : ''}`} onClick={() => setCurrency('inr')} style={{ pointerEvents: (currentPlan?.status === 'ACTIVE' && currentPlan?.name !== 'TRIAL') ? 'none' : 'auto' }}>
                                    INR
                                </span>
                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                            {loader ? Array(2).fill(0).map((_, index) => (shimmerPricingCard())) : pricingCards()}
                        </div>
                    </div>
                </div>
            </div>
            {renderConfirmationModal()}
        </>
    )
}

export default Plan;
