import axios from 'axios';

export const updateViewTracker = (id, email_id, source, params7ts) => dispatch => {
    axios
    .put(process.env.REACT_APP_API_URL + '/view-tracker/', {'id': id, 'email_id': email_id, 'source': source, 'params7ts': params7ts, 'urlPath': typeof window !== 'undefined' ? window.location.href : ''})
    .then(res => {
        console.log("successful")
    })
    .catch(err => {
        console.log(err)
    })
}


export const createViewTracker = (documentId, location) => dispatch => {
    return axios
    .post(process.env.REACT_APP_API_URL + '/view-tracker/', {
        'document_id': documentId,
        'location': location,
    })
    .then(response => {
        return response.data.details.id
    })
    
}

export const getIpDetails = () => dispatch => {
    const instance = axios.create();
    return instance.get('https://ipapi.com/json/')
    .then(res=> {return res.data})
    .catch(err=> {return null})
}