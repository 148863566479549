const creditReducer = (state = {}, action) => {
    switch (action.type) {
        case 'TOTAL_CREDITS':
            return {
                ...state,
                ...action.payload
            }
        case 'CREDITS_TRANSACTION':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default creditReducer;