import styled from 'styled-components';
export const GoogleSignupContainer = styled.div`
  .orSign {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #99acc2;
    line-height: 0.1em;
    color: #99acc2;
    font-size: 0.7375vw;
    margin: 10px 0 20px;

    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }

  .orSign span {
    background: #fff;
    padding: 0 10px;
  }
`;
export const GoogleSignin = styled.div`
  background: #4285f4;
  cursor: pointer;
  color: white;
  font-size: 0.8375vw;
  display: flex;
  align-items: center;
  margin: auto;
  padding: 8px;
  font-weight: 500;
  width: fit-content;
  border-radius: 2px;
  font-family: Roboto;

  img {
    width: 30px;
    height: 30px;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
`;
export const GoogleLogo = styled.div`
  padding: 4px;
  background: white;
  border-radius: 2px;
`;
export const GoogleText = styled.div`
  width: fit-content;
  padding: 0 8px;
`;