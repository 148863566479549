const organizationReducer = (state = {}, action) => {
    switch (action.type) {
        case 'DETAILS_FROM_ORGANIZATION_API':
            return {
                ...state,
                ...action.payload
            }
        case 'ORGANIZATION_LOADER':
            return {
                ...state,
                orgLoader: action.payload
            }
        case 'DETAILS_FROM_SOLUTION_ORGANIZATION_API':
            return {
                ...state,
                orgDetailsFromSolution: action.payload
            }
        case 'DETAILS_FROM_ONBOARD_STATUS':
            return {
                ...state,
                onboard_status: action.payload
            }
        default:
            return state
    }
}

export default organizationReducer;