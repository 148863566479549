import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import '../styles/GraphView.css'
import '../styles/Common.css'
import { Rings } from 'react-loader-spinner'
import { useMediaQuery } from 'react-responsive';


const GraphView = (props) => {
    const { graphData, loading, emailId } = props

    const data = {
        datasets: [
            {
                barThickness: 10,
                label: 'Seconds Viewed',
                backgroundColor: [
                    '#8c52ff',
                    '#5246b3',
                    '#a952ff',
                    '#52a1ff',
                    '#3970b3',
                ],
                borderWidth: 0,
                borderRadius: 5,
            },
        ],
    };

    const options = {
        scales: {
            x: {
                grid: {
                    offset: false,
                    borderDash: [8, 4],
                    color: '#e9ecef',
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 0,
            },
        },
        aspectRatio: 1.25,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            }
        },
    };

    const isMobile = useMediaQuery({ query: '(max-width: 991px)' })

    const renderGraphData = () => {
        if (graphData !== null && graphData !== undefined && graphData.length > 0) {
            var result = []
            for (var i = 0; i < graphData.length; i++) {
                var tempData = JSON.parse(JSON.stringify(data))
                tempData.labels = Object.keys(graphData[i].analysis)
                tempData.datasets[0].data = Object.values(graphData[i].analysis)
                result.push(
                    <div className='row white-background p-3 m-3 grey-shadow'>
                        <p className='table-data m-0'>{new Date(new Date(graphData[i].created_date) - new Date(graphData[i].created_date).getTimezoneOffset() * 60000).toString().split('GMT')[0]}</p>
                        <Bar data={tempData} options={options} />
                    </div>
                )
            }
            return result
        }
        else {
            return ("No one viewed this document yet!")
        }

    }

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row' style={{ marginTop: '2vw' }}>
                    <h4 className='p-2 mt-4 page-heading'>Detailed Analysis</h4>
                    {(graphData !== null && graphData !== undefined && graphData.length > 0) && <p>Email : {emailId}</p>}
                </div>
                {loading ? (<div className='d-flex justify-content-center'><Rings color="#52a1ff" height={120} width={120} /></div>) : renderGraphData()}
            </div>
        </React.Fragment>
    )
}

export default GraphView;