import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import configureStore from './store';
import Amplify, { Auth } from 'aws-amplify';
import Axios from 'axios';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    mandatorySignIn: false,
    storage: localStorage.getItem('rememberMe') === 'false' ? sessionStorage : localStorage,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      redirectSignIn: window.location.hostname === 'localhost'
        ? 'http://localhost:3000'
        : process.env.REACT_APP_BASE_URL,
      redirectSignOut: window.location.hostname === 'localhost'
        ? 'http://localhost:3000' : process.env.REACT_APP_BASE_URL,
      responseType: 'code',
    },
  },
});

Sentry.init({
  dsn: 'https://58a668524a9f4f03b46b58ee5c57ae9a@o4504563853950976.ingest.sentry.io/4504577995309056',
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_STAGE,
  tracesSampleRate: 1.0,
  integrations: [new Sentry.Replay()],
});

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

// Axios request interceptor
Axios.interceptors.request.use(async (config) => {
  if (!isAbsoluteURLRegex.test(config.url)) {
    config.baseURL = process.env.REACT_APP_API_URL;
    config.headers['Access-Control-Allow-Origin'] = '*';
  }

  try {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    const idTokenExpire = session.getIdToken().getExpiration();
    const currentTimeSeconds = Math.round(+new Date() / 1000);

    // Set the Authorization header with the idToken
    config.headers.Authorization = `Bearer ${idToken}`;

    // Check if the idToken is expired or about to expire
    if (idTokenExpire < currentTimeSeconds) {
      const user = await Auth.currentAuthenticatedUser();
      const refreshToken = session.getRefreshToken();

      return new Promise((resolve, reject) => {
        user.refreshSession(refreshToken, (err, data) => {
          if (err) {
            Auth.signOut().then(() => {
              reject(err);
            });
          } else {
            const newIdToken = data.getIdToken().getJwtToken();
            config.headers.Authorization = `Bearer ${newIdToken}`;
            resolve(config);
          }
        });
      });
    }

    return config;
  } catch (error) {
    return config;
  }
}, (error) => Promise.reject(error));

// Axios response interceptor
Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle errors
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        Auth.signOut();
      }
    } else if (error.request) {
      // The request was made but no response was received
      // This might happen if the server is not reachable
      // or there is a network issue
    } else {
      // Something happened in setting up the request that triggered an Error
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={configureStore()}>
        <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
