import { Auth } from 'aws-amplify';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { NotificationManager } from 'react-notifications';
import { getPlans, getActivePlan } from './planAction';
import isEmpty from '../validation/is-empty';
import { getNotificationPreferences } from './notificationPreferenceAction';
import { getOrganizationByPostCall, getOrganizationByGetCall, getOrganizationOnboardStatusByGetCallSolution } from './organizationsAction';

// export const signIn = (username, password) => dispatch => {
//     NotificationManager.info("Logging in...")
//     Auth.signIn(username, password)
//         .then(res => {
//             setAuthToken(res['storage']['CognitoIdentityServiceProvider.' + process.env.REACT_APP_COGNITO_CLIENT_ID + '.' + username + '.idToken'])
//             localStorage.setItem('user', JSON.stringify(res['attributes']))
//             dispatch({
//                 type: 'DETAILS_FROM_AUTH_SIGNIN',
//                 payload: res['attributes']
//             })
//             dispatch({
//                 type: 'SIGNIN'
//             })
//         })
//         .catch(error => {
//             console.log('error signing in', error);
//             NotificationManager.error("Wrong Email Id - Password Combination")
//         });
// }

// export const register = (email, password, name) => dispatch => {
//     NotificationManager.info("Registering...")
//     Auth.signUp({ 'username': email, password, attributes: { name } })
//         .then(res => {
//             dispatch(signIn(email, password))
//         })
//         .catch(error => {
//             console.log('error registering', error);
//             NotificationManager.error("Something Went Wrong")
//         });
// }

export const registerUser = (userData, history, signup) => (dispatch) => {
    dispatch({
        type: 'SET_ERRORS',
        payload: {},
    });
    return Auth.signUp({
        username: uuidv4(),
        password: userData.password,
        attributes: {
            email: userData.email,
            given_name: userData.firstName,
            family_name: userData.lastName,
        },
    })
        .then((res) => {
            dispatch(loginUser(res.user.username, userData.password, history, '', '', signup));
        })
        .catch((err) => {
            NotificationManager.error(err.message)
            dispatch({
                type: 'SET_ERRORS',
                payload: ((err || {}).response || {}).data || 'Some error occurred',
            });
            return 'Failure';
        });
};


export const completePassword = (user, newPassword, history) => (dispatch) => {
    dispatch({
        type: 'SET_ERRORS',
        payload: {},
    });
    return Auth.completeNewPassword(
        user,
        newPassword
    )
        .then((user) => {
            NotificationManager.success('Password changed successfully. Please login with your new password now');
            history.push('/');
            return user;
        })
        .catch((err) => {
            dispatch({
                type: "SET_ERRORS",
                payload: { message: (err || {}).message },
            });
            return 'Failure';
        });
};

export const loginUser = (userName, password, history, state, emailCCToken, signupStatus) => async (dispatch) => {
    dispatch({
        type: 'SET_ERRORS',
        payload: {},
    });
    dispatch({
        type: 'AUTH_LOADING',
        payload: true,
    });
    dispatch({
        type: 'SET_EMAILCCTOKEN',
        payload: emailCCToken,
    });

    try {
        const res = await Auth.signIn(userName, password);

        if (['NEW_PASSWORD_REQUIRED', 'RESET_REQUIRED'].includes((res || {}).challengeName)) {     
            history.push('/complete-new-password');
            dispatch({
                type: 'COMPLETE_PASSWORD',
                payload: res,
            });
        } else {
            dispatch({
                type: 'SIGNIN',
                payload: { loggedIn: true, authLoading: false }
            });
            dispatch({ type: 'IS_AUTHENTICATED', payload: true });
                history.push('/documents');
                // dispatch(getOrganizationByPostCall());
                dispatch(getOrganizationOnboardStatusByGetCallSolution());
        }

        dispatch({
            type: 'AUTH_LOADING',
            payload: false,
        });
        return 'Success';
    } catch (err) {
        NotificationManager.error(err.message)
        dispatch({
            type: 'AUTH_LOADING',
            payload: false,
        });
        dispatch({
            type: 'SET_ERRORS',
            payload: { message: (err || {}).message },
        });
        return 'Failure';
    }
};

export const userLogout = () => (dispatch) => {
    dispatch({
        type: 'EMPTY_AUTH',
    });
    dispatch({
        type: 'EMPTY_DASHBOARD',
    });
    Auth.signOut();
    setAuthToken(false);
    localStorage.clear();
    window.location.href = '/';
};

export const loginUserGoogle = (id_token, history, state) => (dispatch) => {
    dispatch({
        type: 'SET_ERRORS',
        payload: {},
    });
    NotificationManager.info("Logging in...");
    dispatch(setCurrentUserForGoogle(history, state));
};
export const setCurrentUser = (history, state) => (dispatch) => {
    return Auth.currentSession()
        .then((session) => {
            setAuthToken(session.getIdToken().getJwtToken());
            axios
                .get(process.env.REACT_APP_API_URL + '/users')
                .then((res) => {
                    dispatch(getOrganizationOnboardStatusByGetCallSolution());
                    if (history) {
                        NotificationManager.success("Login Successful");
                        dispatch(getOrganizationByGetCall());
                        dispatch(getNotificationPreferences());
                    }
                    dispatch({ type: 'SIGNIN', loggedIn: true });
                    dispatch({
                        type: 'DETAILS_FORM_USER_API',
                        payload: res.data.details
                    });
                    dispatch({
                        type: 'AUTH_LOADING',
                        payload: false,
                    });
                    dispatch({ type: 'IS_AUTHENTICATED', payload: !isEmpty(res.data) });
                    dispatch(getActivePlan());
                    return res;
                })
        })
        .catch((err) => {
            console.log('**************** ERROR WHILE TRYING TO LOG IN : **************', err);
            dispatch({
                type: 'AUTH_LOADING',
                payload: false,
            });
            return 'Failure';
        });
};

export const setCurrentUserForGoogle = (history, state) => (dispatch) => {
    return Auth.currentSession()
        .then((session) => {
            setAuthToken(session.getIdToken().getJwtToken());
            axios
                .get(process.env.REACT_APP_API_URL + '/users')
                .then((res) => {
                    dispatch(getOrganizationOnboardStatusByGetCallSolution());
                    if (history) {
                        NotificationManager.success("Login Successful");
                        dispatch(getOrganizationByGetCall());
                        dispatch(getNotificationPreferences());
                    }
                    dispatch({ type: 'SIGNIN', loggedIn: true });
                    dispatch({
                        type: 'DETAILS_FORM_USER_API',
                        payload: res.data.details
                    });
                    if (!window.location.pathname.includes('/documents')) {
                        history.push('/documents');
                    }
                    dispatch({
                        type: 'AUTH_LOADING',
                        payload: false,
                    });
                    dispatch({ type: 'IS_AUTHENTICATED', payload: !isEmpty(res.data) });
                    dispatch(getActivePlan());
                    return res;
                })
                .catch(() => {
                    // On failure, call getOrganizationByPostCall
                    dispatch(getOrganizationByPostCall())
                        .then(() => {
                            // After success of getOrganizationByPostCall, recall setCurrentUser
                            dispatch(setCurrentUserForGoogle(history, state));
                        })
                        .catch((err) => {
                            console.log('Failed to call getOrganizationByPostCall:', err);
                            dispatch({
                                type: 'AUTH_LOADING',
                                payload: false,
                            });
                        });
                    return 'Failure';
                });
        })
        .catch((err) => {
            console.log('**************** ERROR WHILE TRYING TO LOG IN : **************', err);
            dispatch({
                type: 'AUTH_LOADING',
                payload: false,
            });
            return 'Failure';
        });
};


export const forgotPassword = (email) => dispatch => {
    NotificationManager.info("Sending reset email...")
    Auth.forgotPassword(email)
        .then(res => {
            NotificationManager.success("Check your email for password reset code")
        })
        .catch(error => {
            console.log('error forgot pasword', error);
            NotificationManager.error("Something Went Wrong")
        });
}

export const resetPasswordSubmit = (userData, history) => (dispatch) => {
    dispatch({
        type: 'SET_ERRORS',
        payload: {},
    });
    Auth.forgotPasswordSubmit(
        userData.username,
        userData.code,
        userData.new_password
    )
        .then((res) => {
            NotificationManager.success("Password reset successfully")
            history.push('/');
        })
        .catch((err) => {
            dispatch({
                type: 'SET_ERRORS',
                payload: { message: (err || {}).message },
            });
        });
};

export const forgotPasswordSubmit = (email, code, newPasssword) => dispatch => {
    Auth.forgotPasswordSubmit(email, code, newPasssword)
        .then(res => {
            NotificationManager.success("Password reset successful. Please login again")
        })
        .catch(error => {
            console.log('error forgot pasword', error);
            NotificationManager.error("Something Went Wrong")
        });
}

export const signOut = () => dispatch => {
    localStorage.removeItem('user')
    Auth.signOut()
        .then(() => {
            setAuthToken(false)
            dispatch({
                type: 'USER_SIGNOUT',
            })
            return {
                type: 'SIGNOUT'
            }
        })
        .catch(error => console.log('error signing out', error));
}

export const confirmUserSignup = (username, code) => dispatch => {
    return Auth.confirmSignUp(username, code)
        .then(res => {
            NotificationManager.success('Account Successfully activated');
            return res;
        })
        .catch(err => {
            NotificationManager.error('Account Activation failed')
            return "Failure";
        });
};

export const updateName = (name) => dispatch => {
    Auth.currentAuthenticatedUser()
        .then(user => {
            Auth.updateUserAttributes(user, {
                'name': name
            })
                .then(res => {
                    // NotificationManager.success('Name Successfully updated');
                    Auth.currentAuthenticatedUser()
                        .then(updatedUser => {
                            dispatch({
                                type: 'USER_NAME',
                                payload: updatedUser.attributes.name
                            })
                        })
                })
                .catch(err => {
                    // NotificationManager.error('Updating Name failed')
                    return "Failure";
                });
        })

};


export const setAuthToken = id_token => {
    if (id_token) {
        //apply to every request
        axios.defaults.headers.common = { Authorization: `Bearer ${id_token}` };
    } else {
        //Delete auth header
        delete axios.defaults.headers.common["Authorization"];
    }
};