import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import _ from 'lodash';
import { exportAllViews } from '../actions/viewAction';
import { deleteDocumentById, getAllDocuments, getUploadPresignedURL } from '../actions/documentAction';
import '../styles/DocumentsTable.css';
import '../styles/Common.css';
import { b64EncodeUnicode } from '../utils';
import { useMediaQuery } from 'react-responsive';
import UploadModal from './UploadModal';
import { ThreeDots } from 'react-loader-spinner';
import {
    Input, Tooltip, Popconfirm
} from 'antd';

const shimmerTypes = [
    'shimmer-thumbnail',
    'shimmer-text',
    'shimmer-button',
    'shimmer-button',
    'shimmer-button',
    'shimmer-text-lastCol',
    'shimmer-text-lastCol',
    'shimmer-text-lastCol'
];

const DocumentsTable = (props) => {
    const dispatch = useDispatch();
    let documents = useSelector(state => state.documentReducer);
    const [exportLoading, setExportLoading] = useState({});
    const [showPopup, setShowPopup] = useState(false);

    const copyLink = (event, document) => {
        event.preventDefault();
        navigator.clipboard.writeText(process.env.REACT_APP_BASE_URL + "/document/" + b64EncodeUnicode(document.file_key) + '?email=')
        NotificationManager.success("Link copied to clipboard")
    }
    const exportCSV = (event, document_id) => {
        event.preventDefault();
        setExportLoading(prev => ({ ...prev, [document_id]: true }));

        dispatch(exportAllViews(document_id))
            .then(() => {
                setExportLoading(prev => ({ ...prev, [document_id]: false }));
            })
            .catch(() => {
                setExportLoading(prev => ({ ...prev, [document_id]: false }));
            });
    }

    // useEffect(() => {
    //     setIsLoading(documents.document_loader);
    // }, [documents.document_loader]);
    const deleteDocument = (document_id) => {
        dispatch({
            type: 'DOCUMENTS_LOADING',
            payload: { document_loader: true }
        });
        dispatch(deleteDocumentById(document_id))
    }

    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const cancel = (e) => {
        setShowPopup(false)
    };

    const renderDocuments = () => {
        if (documents.all_documents && documents.all_documents.length > 0) {
            return documents.all_documents.map((x, i) => {
                // Add border-bottom to the last element
                const isLastItem = i === documents.all_documents.length - 1;
                return (
                    <div key={x.id}>
                        {
                            isMobile ?
                                <Link to={"/views/" + x.id} style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <div className='w-100 border mt-2 p-2 d-flex flex-column justify-content-start align-items-center mobile-table'>
                                        <div className='d-flex mobile-box-1'>
                                            {
                                                x.processed ?
                                                    <img src={'https://doculens-documents-thumbnail-' + process.env.REACT_APP_AWS_ACCOUNT_ID + '-' + process.env.REACT_APP_STAGE + '.s3.amazonaws.com/' + encodeURI(x.file_key.replace('.pdf', '.jpg'))} className='thumbnail' alt='thumbnail' />
                                                    : null
                                            }
                                            <p className='mobile-name'>{x.name}</p>
                                        </div>
                                        <div className='d-flex mobile-box-2'>
                                            <p className='mobile-details'>Pages</p>
                                            <p className='mobile-details'>Views</p>
                                        </div>
                                        <div className='d-flex mobile-box-2'>
                                            <p className=''>{x.total_pages}</p>
                                            <p className=''>{x.total_views}</p>
                                        </div>
                                        <div className='w-100 d-flex mobile-box-2'>
                                            {
                                                x.processed ?
                                                    <button className='btn dark-blue-button' onClick={e => copyLink(e, x)}><i className="bi bi-share-fill light-purple-color"></i></button>
                                                    : <button className='dark-blue-button'>Processing...</button>
                                            }
                                            <button className='btn dark-purple-button' onClick={e => exportCSV(e, x.id)}><i className="bi bi-file-earmark-arrow-down-fill dark-purple-color"></i></button>
                                            <Popconfirm
                                                title="Delete the document"
                                                description="Are you sure to delete this document?"
                                                onConfirm={(e) => { e.stopPropagation(); e.preventDefault(); deleteDocument(x.id); }}
                                                onCancel={(e) => { e.stopPropagation(); e.preventDefault(); cancel(); }}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <button className='light-blue-button' onClick={e => { e.stopPropagation(); e.preventDefault(); setShowPopup(true); }}><i className="bi bi-trash-fill dark-purple-color"></i></button>
                                            </Popconfirm>
                                        </div>
                                    </div>
                                </Link>
                                :
                                <Link to={"/views/" + x.id} style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <div className={isLastItem ? 'row highlight align-items-center pt-2 pb-2 table-border table-border-last' : 'row highlight align-items-center pt-2 pb-2 table-border'}>
                                        <div className='col-6 d-flex justify-content-start align-items-center p-0'>
                                            {
                                                x.processed ?
                                                    <img src={'https://doculens-documents-thumbnail-' + process.env.REACT_APP_AWS_ACCOUNT_ID + '-' + process.env.REACT_APP_STAGE + '.s3.amazonaws.com/' + encodeURI(x.file_key.replace('.pdf', '.jpg'))} className='thumbnail' alt='thumbnail' />
                                                    : null
                                            }
                                            <p className='table-data name'>{x.name}</p>
                                        </div>
                                        <div className='col-3 d-flex justify-content-center'>
                                            {
                                                x.processed ?
                                                    <button className='dark-blue-button' onClick={e => copyLink(e, x)}>Share</button>
                                                    : <button className='dark-blue-button'>Processing...</button>
                                            }
                                            {!exportLoading[x.id] ? (
                                                <button className='dark-purple-button' onClick={e => exportCSV(e, x.id)}>Export</button>
                                            ) : (
                                                <div className="dark-purple-button d-flex align-items-center justify-content-center">
                                                    <ThreeDots
                                                        height="20"
                                                        width="20"
                                                        color='#a952ff'
                                                        ariaLabel="loading"
                                                    />
                                                </div>
                                            )}

                                            <Popconfirm
                                                title="Delete the document"
                                                description="Are you sure to delete this document?"
                                                onConfirm={(e) => { e.stopPropagation(); e.preventDefault(); deleteDocument(x.id); }}
                                                onCancel={(e) => { e.stopPropagation(); e.preventDefault(); cancel(); }}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <button className='light-blue-button' onClick={e => { e.stopPropagation(); e.preventDefault(); setShowPopup(true); }}>Delete</button>
                                            </Popconfirm>
                                        </div>
                                        <div className='col-1'>
                                            <p className='table-data text-center'>{x.total_pages}</p>
                                        </div>
                                        <div className='col-1'>
                                            <p className='table-data text-center'>{x.total_views}</p>
                                        </div>
                                        <div className='col-1'>
                                            <p className='table-data text-center'>{new Date(new Date(x.created_date) - new Date(x.created_date).getTimezoneOffset() * 60000).toLocaleDateString()}</p>
                                        </div>
                                    </div>
                                </Link>
                        }
                    </div >
                );
            });
        } else {
            return (
                <div className='row text-center pt-2 mt-2 pb-2'>
                    <p className='m-0 p-0'>Currently, there are no documents. Upload some to begin.</p>
                </div>
            );
        }
    };

    return (
        <React.Fragment>
            <div className='container pb-3'>
                <UploadModal />
                {!isMobile &&
                    <div className='row mt-4' style={{ margin: '0' }}>
                        <div className='col-6'>
                            <p className='table-heading'>Name</p>
                        </div>
                        <div className='col-3'>
                        </div>
                        <div className='col-1'>
                            <p className='table-heading'>Pages</p>
                        </div>
                        <div className='col-1'>
                            <p className='table-heading'>Views</p>
                        </div>
                        <div className='col-1'>
                            <p className='table-heading'>Uploaded</p>
                        </div>
                    </div>
                }
                {props.isDocumentLoading || documents.document_loader ? (
                    <div>
                        {
                            !isMobile ?
                                Array(3).fill(0).map((_, index) => (
                                    <div className="shimmer" key={index}>
                                        {shimmerTypes.map((type, idx) => (
                                            <div key={idx} className={`shimmer-item ${type}`}></div>
                                        ))}
                                    </div>
                                ))
                                : Array(3).fill(0).map((_, index) => (
                                    <div className="shimmer" key={index}>
                                        <div className='shimmer-item'><div className='shimmer-thumbnail' /><div className='shimmer-text' /></div>
                                        <div className='shimmer-item justify-content-center'><div className='shimmer-text' style={{ width: '20%' }} /><div className='shimmer-text' style={{ width: '20%' }} /></div>
                                        <div className='shimmer-item justify-content-center mt-3'><div className='shimmer-button' style={{ width: '10%' }} /><div className='shimmer-button' style={{ width: '10%' }} /><div className='shimmer-button' style={{ width: '10%' }} /></div>
                                    </div>
                                ))
                        }
                    </div>
                ) : (renderDocuments())}
            </div>
        </React.Fragment>

    )
}

export default DocumentsTable;