import React, { useState } from 'react';
import '../styles/ViewsTable.css'
import '../styles/Common.css'
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Icon } from "../logos/downloaded.svg";
import { NotificationManager } from 'react-notifications';
import { Link, useRouteMatch } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { ReactComponent as CopyIcon } from "../logos/clipboard-regular.svg";
import ReactDOMServer from 'react-dom/server';
import { Rings } from 'react-loader-spinner'
import GraphView from './GraphView';
import { useMediaQuery } from 'react-responsive';


const ViewsTable = (props) => {
    const { views, selectedView, graphData, loading } = props
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
    const [isViewSelected, setIsViewSelected] = useState(false)
    let { url } = useRouteMatch();

    const copyEmail = (email) => {
        navigator.clipboard.writeText(email);
        NotificationManager.success("Email copied to clipboard")
    }

    const renderTable = () => {
        var colors = ['email-thumb-dark-blue', 'email-thumb-dark-purple', 'email-thumb-light-purple', 'email-thumb-light-blue']
        if (views && views.length > 0) {
            let result = new Array(views.length).fill(0).map((zero, i) => {
                var x = views[i]
                var selected_color = colors[i % 4]
                var if_selected = selectedView === x.email_id;

                return (
                    (isMobile) ?
                        <div>
                            {isViewSelected &&
                                <div className='view-wrapper' >
                                    <div className='cross' onClick={() => {
                                        setIsViewSelected(false)
                                    }}>x</div>
                                    <GraphView graphData={graphData} emailId={selectedView} />
                                </div>
                            }
                            <div onClick={() => { props.onChangeSelectedView(x.email_id); setIsViewSelected(true) }}>
                                <div className=' border mt-3 p-3 d-flex flex-column justify-content-center align-items-center mobile-table'>
                                    <div className='col-12 d-flex align-items-center'>
                                        <div className={selected_color + ' d-flex justify-content-center align-items-center'}>{x.email_id.charAt(0).toUpperCase()}</div>
                                        <p data-effect='solid' data-html={true} data-tip={ReactDOMServer.renderToString(<div>Click to Copy <CopyIcon fill='white' style={{ height: "15px", margin: '0px 0px 4px 3px' }} /></div>)} className='table-data name viewer-email' onClick={(e) => copyEmail(x.email_id)}>{x.email_id}</p>
                                        <ReactTooltip />
                                    </div>
                                    <div className='d-flex mobile-box-2'>
                                        <p className='mobile-details'>Downloaded</p>
                                        <p className='mobile-details'>Pages</p>
                                        <p className='mobile-details'>Last Viewed</p>
                                    </div>
                                    <div className='d-flex mobile-box-2'>
                                        <p className=''>{x.total_pages}</p>
                                        <p className=''>{x.total_views}</p>
                                        <p className=''>{new Date(x.last_viewed).toLocaleDateString()}</p>
                                    </div>
                                    <div>
                                        <span className='more-details-span'>Click to know more {">>"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={if_selected ? 'row highlight align-items-center pt-2 pb-2 me-0 highlight-selected' : 'row highlight align-items-center pt-2 pb-2 me-0 table-border-view-table'} onClick={() => props.onChangeSelectedView(x.email_id)}>
                            <div className='col-5 d-flex justify-content-start align-items-center'>
                                <div className={selected_color + ' d-flex justify-content-center align-items-center'}>{x.email_id.charAt(0).toUpperCase()}</div>
                                <p data-effect='solid' data-html={true} data-tip={ReactDOMServer.renderToString(<div>Click to Copy <CopyIcon fill='white' style={{ height: "15px", margin: '0px 0px 4px 3px' }} /></div>)} className='table-data name viewer-email' onClick={(e) => copyEmail(x.email_id)}>{x.email_id}</p>
                                <ReactTooltip />
                            </div>
                            <div className='col-2 d-flex justify-content-center'>
                                <a href={"mailto:" + x.email_id} target="_blank" rel="noreferrer" title={x.email_id} style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <button className='dark-purple-button'>Email</button>
                                </a>
                            </div>
                            <div className='col-2 d-flex justify-content-center'>
                                {x.downloaded ? (<Icon fill='rgb(32,198,58)' className='download-icon table-data' />) : (<></>)}
                            </div>
                            <div className='col-1'>
                                <p className='table-data text-center'>{x.total_views}</p>
                            </div>
                            <div className='col-2'>
                                <p className='table-data text-center'>{new Date(x.last_viewed).toLocaleDateString()}</p>
                            </div>
                        </div>
                )
            })
            return result
        } else {
            return (
                <div className='row text-center pt-2 mt-2 pb-2'>
                    <p className='m-0 p-0'>No one viewed this document yet!</p>
                </div>
            )
        }
    }
    const shimmerTypes = [
        'shimmer-views-thumbnail',
        'shimmer-views-text',
        'shimmer-views-button',
        'shimmer-text-lastCol',
        'shimmer-text-lastCol',
        'shimmer-text-lastCol'
    ];
    return (
        <React.Fragment>
            <div className='container-fluid main-view right-border pe-0 pb-3'>
                <div className='row w-100'>
                    <h4 className='p-2 ps-3 page-heading'>Views</h4>
                </div>
                {(!isMobile) &&
                    <div className='row mt-4 division me-0'>
                        <div className='col-5'>
                            <p className='table-heading'>Email</p>
                        </div>
                        <div className='col-2'>
                        </div>
                        <div className='col-2'>
                            <p className='table-heading text-center'>Downloaded</p>
                        </div>
                        <div className='col-1'>
                            <p className='table-heading text-center'>Views</p>
                        </div>
                        <div className='col-2'>
                            <p className='table-heading text-center'>Last Viewed</p>
                        </div>
                    </div>}
                {loading ? (<div className='d-flex flex-column justify-content-center'>
                    {/* Shimmer UI while loading */}
                    {Array(3).fill(0).map((_, index) => (
                        <div className="shimmer" key={index}>
                            {shimmerTypes.map((type, idx) => (
                                <div key={idx} className={`shimmer-item ${type}`} style={{ margin: '0.4vw 0.3vw' }}></div>
                            ))}
                        </div>
                    ))}
                </div>) :

                    renderTable()
                }
            </div>
        </React.Fragment>
    )
}

export default ViewsTable;