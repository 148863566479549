/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "../styles/ProfilePage.css";
import Navbar from './Navbar';
import DefaultLogo from '../images/default-logo.jpg';
import ImageUpload from './ImageUpload';
import { updateOrganizationCallSolution, getOrganizationByGetCall } from '../actions/organizationsAction';
import { NavLink, useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { ThreeDots } from 'react-loader-spinner';
import { Modal, Button, Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { getAssistantsByGetCallSolution } from '../actions/assistantAction';
import { inviteUserToOrg, getUserInfoFromSolution } from '../actions/userAction';
import { NotificationManager } from 'react-notifications';

function ProfilePage() {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [userEmail, setUserEmail] = useState(''); // New state for invited user's email
  const [userPassword, setUserPassword] = useState(''); // New state for invited user's password
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false); // New modal state
  const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility
  const organization = useSelector(state => state.organizationReducer);
  const [company, setCompany] = useState(null);
  const [editCompany, setEditCompany] = useState(false);
  const inputCompany = useRef(null);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);
  const assistants = useSelector(state => state.assistantsReducer);

  // Modal form state
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [inviteButtonLoading, setInviteButtonLoading] = useState(false);
  const [openModalInvite, setOpenModalInvite] = useState(false);
  
  const user = useSelector(state => state.userReducer);

  useEffect(() => {
    setCompany(organization.name);
    setSaveLoader(organization.orgLoader);
    dispatch(getUserInfoFromSolution());
    dispatch(getAssistantsByGetCallSolution());
  }, [organization]);

  useEffect(() => {
    if (user.invitedUserDetails && Object.keys(user.invitedUserDetails).length !== 0) {
      setUserEmail(user.invitedUserDetails.email);
      setUserPassword(user.invitedUserDetails.tempPassword);
      setShowUserDetailsModal(true);
      setOpenModal(false);
      setOpenModalInvite(false);
    }
    setInviteButtonLoading(user.invitedUserLoader);
  }, [user.invitedUserDetails, user.invitedUserLoader]);

  useEffect(() => {
    if (editCompany) {
      inputCompany.current.focus();
    }
  }, [editCompany]);

  const onClickInviteUserPopButton = () => {
    setOpenModalInvite(true);
  };

  const onClickInviteUser = () => {
    setInviteButtonLoading(true);
    const inviteDetails = {
      email,
      firstName,
      lastName,
      phoneNumber,
    };
    dispatch(inviteUserToOrg(inviteDetails, assistants[0].virtualAssistentDTO.id));
  };

  const saveCompany = () => {
    dispatch({
      type: 'ORGANIZATION_LOADER',
      payload: true,
    });
    setSaveLoader(true);
    if (company !== organization.name) {
      let temp = { ...organization };
      temp.name = company;
      dispatch(updateOrganizationCallSolution(temp));
    }
    setEditCompany(false);
  };

  useEffect(() => {
    if (Object.keys(organization).length === 0) {
      dispatch(getOrganizationByGetCall());
    }
  }, [dispatch, organization]);

  const isInviteUserButtonEnabled = () => {
    return (email && firstName && lastName) || inviteButtonLoading;
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    NotificationManager.success('Password copied to clipboard!')
  };

  const resetInviteForm = () => {
    setEmail('');
    setFirstName('');
    setLastName('');
    setPhoneNumber('');
    setInviteButtonLoading(false);
  };


  return (
    <div className='profile-wrapper'>
      <Navbar />
      <div className="d-flex align-items-start w-100 h-100 profile-content-wrapper container" style={{ flexDirection: window.innerWidth < 768 && 'column' }}>
        <div className="col-12 col-md-1 px-0" style={{ height: window.innerWidth > 768 && '100%', width: window.innerWidth > 768 && '12vw', textAlign: 'unset' }}>
          <Nav variant="tabs" className="flex-column">
            <Nav.Item>
              <Nav.Link
                as={NavLink}
                to="/profile"
                className={`link ${activeTab === '/profile' ? 'active' : ''}`}
                onClick={() => setActiveTab('/profile')}
              >
                Profile
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={NavLink}
                to="/organization"
                className={`link ${activeTab === '/organization' ? 'active' : ''}`}
                onClick={() => setActiveTab('/organization')}
              >
                Organization
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={NavLink}
                to="/notification"
                className={`link ${activeTab === '/notification' ? 'active' : ''}`}
                onClick={() => setActiveTab('/notification')}
              >
                Notification
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={NavLink}
                to="/reset-password"
                className={`link ${activeTab === '/reset-password-link' ? 'active' : ''}`}
                onClick={() => setActiveTab('/reset-password-link')}
              >
                Reset Password
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <div className="col-12 col-md-11 px-0" style={{ marginTop: '2vw', marginLeft: '5vw' }}>
          <div className='profile-block'>
            <h2>Organization Settings</h2>
            <div className='profile-box d-flex align-items-center justify-content-center profile-page-image '>
              <img
                className='profile-page-image'
                src={organization.logo_key ? `https://doculens-organization-logos-879924793392-${process.env.REACT_APP_STAGE}.s3.amazonaws.com/${organization.logo_key}` : DefaultLogo}
                alt='organization'
              />
              <div className="overlay-profile-picture" onClick={() => setOpenModal(true)}>
                <div className="text-profile-picture">Change Company Logo</div>
              </div>
            </div>
            <ImageUpload isOpen={openModal} closeModal={() => setOpenModal(false)} />
            <div className='d-flex profile-column-wrapper' style={{ marginTop: '10px', marginBottom: '10px' }}>
              <div className='d-flex flex-column profile-row'>
                <label>Company Name</label>
              </div>
              <div className='d-flex flex-column profile-row'>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Company"
                  ref={inputCompany}
                  value={company}
                  onChange={e => setCompany(e.target.value)}
                />
              </div>
            </div>
            {saveLoader ? (
              <ThreeDots color="#a952ff" height={20} width={20} style={{ paddingLeft: '45%', paddingTop: '20%', paddingBottom: '20%' }} />
            ) : (
              <>
                  <button className='btn dark-blue-button profile-page-button' type='button' onClick={saveCompany}>Save</button>
                  {user.userFromSolution?.authorities.includes('ROLE_ORG_ADMIN') && <button type='button' className='btn dark-purple-button profile-page-button' onClick={onClickInviteUserPopButton}>Invite User</button>}
              </>
            )}
          </div>
        </div>

        {/* Invite User Modal */}
        <Modal show={openModalInvite} onHide={() => {
          if (!inviteButtonLoading) {
            setOpenModalInvite(false);
            resetInviteForm();
          }
        }} centered dialogClassName="custom-modal">
          <Modal.Header closeButton={!inviteButtonLoading}>
            <Modal.Title>Invite User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formEmail">
                <Form.Label>Email <sup style={{ color: 'red' }}>*</sup></Form.Label>
                <Form.Control type="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
              </Form.Group>
              <Form.Group controlId="formFirstName" className='inviteUserContainer'>
                <Form.Label>First Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                <Form.Control type="text" placeholder="Enter first name" value={firstName} onChange={e => setFirstName(e.target.value)} />
              </Form.Group>
              <Form.Group controlId="formLastName" className='inviteUserContainer'>
                <Form.Label>Last Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                <Form.Control type="text" placeholder="Enter last name" value={lastName} onChange={e => setLastName(e.target.value)} />
              </Form.Group>
              <Form.Group controlId="formPhoneNumber" className='inviteUserContainer'>
                <Form.Label>Phone Number</Form.Label>
                <PhoneInput placeholder="Enter phone number" value={phoneNumber} onChange={setPhoneNumber} />
              </Form.Group>
              <Modal.Footer>
                <button className='btn inviteButton' type='button' onClick={onClickInviteUser} disabled={!isInviteUserButtonEnabled() || inviteButtonLoading}>
                  {inviteButtonLoading ? (
                    <ThreeDots color="#fff" height={20} width={20} />
                  ) : 'Invite User'}</button>
                <button className='btn closeButton' type='button' onClick={() => { setOpenModalInvite(false); resetInviteForm(); }} disabled={inviteButtonLoading}>
                  Cancel
                </button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>

        {/* User Invited Successfully Modal */}
        <Modal show={showUserDetailsModal} onHide={() => { setShowUserDetailsModal(false); resetInviteForm(); }} centered>
          <Modal.Header closeButton>
            <Modal.Title>User Invited Successfully</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" value={userEmail} readOnly />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Password</Form.Label>
              <div className="input-group">
                <Form.Control
                  type={passwordVisible ? "text" : "password"}
                  value={userPassword}
                  readOnly
                />
                <Button variant="outline-secondary" onClick={togglePasswordVisibility}>
                  {passwordVisible ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                </Button>

              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className='copyButton' onClick={() => copyToClipboard(userPassword)}>
              Copy Password
            </Button>
            <Button className="closeButton" onClick={() => { setShowUserDetailsModal(false); resetInviteForm(); }}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </div>
  );
}

export default ProfilePage;
