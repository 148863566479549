/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import DefaultLogo from '../images/default-logo.jpg'
import '../styles/ProfileHeading.css'
import '../styles/Common.css'
import { updateName } from '../actions/authAction'
import { updateUser } from '../actions/userAction'
import ImageUpload from './ImageUpload';
import { useMediaQuery } from 'react-responsive'
import { getAllDocuments } from '../actions/documentAction';
import {
  Input, Tooltip, Popconfirm
} from 'antd';


const ProfileHeading = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const user = useSelector(state => state.userReducer);
  const organization = useSelector(state => state.organizationReducer);
  const credits = useSelector(state => state.creditReducer);
  const documents = useSelector(state => state.documentReducer);
  const plan = useSelector(state => state.planReducer);
  const [phone, setPhone] = useState(null)
  const [company, setCompany] = useState(null)
  const [name, setName] = useState(null)
  const [calendarURL, setCalendarURL] = useState(null)
  const [editPhone, setEditPhone] = useState(false)
  const [editCompany, setEditCompany] = useState(false)
  const [editName, setEditName] = useState(false)
  const [editCalendarURL, setEditCalendarURL] = useState(false)
  const inputPhone = useRef(null);
  const inputCompany = useRef(null);
  const inputName = useRef(null);
  const inputCalendarURL = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showReset, setShowReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTrialExpired, setIsTrialExpired] = useState(false);
  useEffect(() => {
    setPhone(user.phone_number)
    setCompany(organization.name)
    setName(user.name)
    setCalendarURL(user.calendar_url)
  }, [user, organization]);

  useEffect(() => {
    if (plan && plan.activePlan) {
      const currentDate = new Date();
      const expiryDate = plan.activePlan.expiryDate ? new Date(plan.activePlan.expiryDate) : null;

      if (plan.activePlan.name === 'TRIAL' && expiryDate && expiryDate < currentDate) {
        setIsTrialExpired(true);
      } else {
        setIsTrialExpired(false);
      }
    } else {
      setIsTrialExpired(false);
    }
  }, [plan]);

  useEffect(() => {
    if (editPhone) {
      inputPhone.current.focus()
    }
    if (editCompany) {
      inputCompany.current.focus()
    }
    if (editName) {
      inputName.current.focus()
    }
    if (editCalendarURL) {
      inputCalendarURL.current.focus()
    }
  }, [editPhone, editCompany, editName, editCalendarURL])

  const saveCompany = () => {
    if (company !== user.company_name) {
      let temp = user
      temp.company_name = company
      dispatch(updateUser(temp))
    }
    setEditCompany(false)
  }

  const saveCalendarURL = () => {
    if (calendarURL !== user.calendar_url) {
      let temp = user
      temp.calendar_url = calendarURL
      dispatch(updateUser(temp))
    }
    setEditCalendarURL(false)
  }

  const savePhone = () => {
    if (phone !== user.phone_number) {
      let temp = user
      temp.phone_number = phone
      dispatch(updateUser(temp))
    }
    setEditPhone(false)
  }

  const saveName = () => {
    if (name !== user.name) {
      dispatch(updateName(name))
      let temp = user
      temp.name = name
      dispatch(updateUser(temp))
    }
    setEditName(false)
  }

  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const onChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  }

  const filterDocuments = async (e) => {
    dispatch({
      type: 'DOCUMENTS_LOADING',
      payload: { document_loader: true }
    })
    const searchText = searchQuery.trim();
    if (searchText) {
      await Promise.resolve(dispatch(getAllDocuments(searchText)));

      setShowReset(true);
    }
    setIsLoading(false);
  };

  const onSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      filterDocuments();
    }
  };

  const resetSearchQuery = async () => {
    setSearchQuery('');
    setShowReset(false);
    setIsLoading(true);
    dispatch({
      type: 'DOCUMENTS_LOADING',
      payload: { document_loader: true }
    });

    await Promise.resolve(dispatch(getAllDocuments()));

    setIsLoading(false);
  };

  const refreshDocuments = async () => {
    dispatch({
      type: 'DOCUMENTS_LOADING',
      payload: { document_loader: true }
    })
    const searchText = searchQuery.trim();
    if (searchText) {
      await Promise.resolve(dispatch(getAllDocuments(searchText)));
      setIsLoading(false);
    } else {
      await Promise.resolve(dispatch(getAllDocuments()));
      setIsLoading(false);
    }
    setIsLoading(false);
  };


  return (
    <React.Fragment>
      <div className='container-fluid division w-100'>
        {isTrialExpired && (
          <div className="alert alert-danger container" role="alert" style={{ textAlign: 'center' }}>
            Thanks for choosing Doculens.
            Your trial has expired. <a href="/plan" className="alert-link" style={{ textDecoration: 'underline' }}>Subscribe</a> now to continue accessing all features.
          </div>
        )}
        <div className='container mt-4'>
          <div className='d-flex pb-md-3 pb-lg-3 flex-column flex-lg-row flex-xl-row flex-xxl-row'>

            <div className='col-12 col-sm-8 col-md-8 col-lg-10 box-parent'>
              {/* <div className='d-flex justify-content-start w-100 my-box'>
                <div className='profile-box'>
                  <img className='profile-user-image' src={organization.logo_key ? `https://doculens-organization-logos-879924793392-${process.env.REACT_APP_STAGE}.s3.amazonaws.com/${organization.logo_key}`: DefaultLogo} alt='user'></img>
                  
                </div>
                <ImageUpload isOpen={openModal} closeModal={e => setOpenModal(false)} />
                <div className='ms-4 align-self-center'>
                  
                      <p className='profile_user_name' >{name}</p>
                  
                  
                      {phone && <p className='profile_extra_info' ><i className="bi bi-telephone-fill profile-heading-icon light-purple-color"></i>{phone}</p>}
                  
                  
                      {company && <p className='profile_extra_info' ><i className="bi bi-people-fill profile-heading-icon dark-blue-color"></i>{company}</p>}
                  

                  
                      {calendarURL && <p className='profile_extra_info' ><i className="bi bi-calendar-check-fill profile-heading-icon light-blue-color"></i>{calendarURL}</p>}
                  
                </div>
              </div> */}
              <div className='col-12' style={{ display: 'flex', alignItems: window.innerWidth < 767 ? 'flex-start' : 'center', gap: '10px', flexDirection: window.innerWidth < 767 && 'column', width: window.innerWidth < 767 && '100%' }}>
                <h4 className='p-2 page-heading' style={{ padding: window.innerWidth < 767 && '0', display: window.innerWidth < 767 && 'none' }}>DOCUMENTS</h4>
                <div className={window.innerWidth < 767 ? 'p-2 searchDiv' : 'searchDiv'}>
                  <Tooltip
                    title="Press enter after entering keyword"
                    trigger="focus"
                  >
                    <Input
                      value={searchQuery}
                      onChange={onChangeSearchQuery}
                      suffix={(
                        <i
                          className="fas fa-search"
                          onClick={filterDocuments}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                      onKeyDown={onSearchKeyDown}
                      placeholder="Search Documents by name"
                    />
                  </Tooltip>
                </div>
                {showReset && (

                  <div onClick={resetSearchQuery} style={{
                    fontSize: window.innerWidth < 767 ? '4vw' : '0.9vw',
                    color: '#A952FF',
                    cursor: 'pointer',
                    fontWeight: '600',
                    width: window.innerWidth < 767 && "100%",
                    textAlign: window.innerWidth < 767 && "center",
                  }} className={window.innerWidth < 767 && 'p-2'}>
                    Reset Search
                  </div>
                )
                }
                <div onClick={refreshDocuments} style={{
                  fontSize: window.innerWidth < 767 ? '4vw' : '0.9vw',
                  color: '#A952FF',
                  cursor: 'pointer',
                  fontWeight: '600',
                  width: window.innerWidth < 767 && "100%",
                  textAlign: window.innerWidth < 767 && "center",
                }} className={window.innerWidth < 767 && 'p-2'}>
                  {isMobile ? <><i className="bi bi-arrow-clockwise" style={{ marginRight: '5px'}}/>Refresh</> : <Tooltip title='Refresh'>
                  <i className="bi bi-arrow-clockwise"/>
                  </Tooltip>}
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-4 col-md-4 col-lg-2 align-self-start extras-box pb-3 pb-lg-0'>
              <div className='d-flex flex-row flex-md-row flex-lg-row pt-4 pt-lg-0 details-box'>
                <div className='col-4 col-sm-6 col-md-4 col-lg-4 '>
                  <div className='d-flex justify-content-center w-100'>
                    {!isMobile ?
                      <div className='value-point-div light-purple-color-background d-flex flex align-items-center justify-content-center w-50'>
                        <i className="bi bi-file-earmark-fill value-point-icon" />
                        <div className='value-point-description w-50 d-flex flex-column align-items-center mobile-row'>
                          <p className='value-point-number text-center'>{(documents.all_documents || []).length}</p>
                        </div>
                      </div>
                      :
                      <div className='value-point-description w-50 d-flex flex-column align-items-center mobile-row'>
                        <p className='value-point-heading'>Documents</p>
                        <p className='value-point-number text-center'>{(documents.all_documents || []).length}</p>
                      </div>
                    }
                  </div>
                </div>
                <div className='col-4 col-sm-6 col-md-4 col-lg-4 '>
                  <div className='d-flex justify-content-center'>
                    {!isMobile ?
                      <div className='value-point-div dark-blue-color-background d-flex align-items-center justify-content-center w-50'>
                        <i className="bi bi-eye-fill value-point-icon"></i>
                        <div className='value-point-description w-50 d-flex flex-column align-items-center mobile-row'>
                          <p className='value-point-number text-center'>{(documents.all_documents || []).map(i => i.total_views).reduce((a, b) => a + b, 0)}</p>
                        </div>
                      </div> :
                      <div className='value-point-description w-50 d-flex flex-column align-items-center mobile-row'>
                        <p className='value-point-heading'>Views</p>
                        <p className='value-point-number text-center'>{(documents.all_documents || []).map(i => i.total_views).reduce((a, b) => a + b, 0)}</p>
                      </div>
                    }
                  </div>
                </div>

                {/* <div className='col-4 col-sm-6 col-md-4 col-lg-4 '>
                  <div className='d-flex justify-content-center'>
                    {!isMobile &&
                    <div className='value-point-div light-blue-color-background d-flex align-items-center justify-content-center w-50'>
                      <i className="bi bi-currency-exchange value-point-icon"></i>
                    </div>
}
                    <div className='value-point-description w-50 d-flex flex-column align-items-center justify-content-center mobile-row'>
                      <p className='value-point-heading'>Credits</p>
                      <p className='value-point-number text-center'>{credits.total_credits}</p>
                    </div>
                  </div>
                </div> */}

              </div>

            </div>
          </div>
        </div>
      </div>

    </React.Fragment >
  )
}

export default ProfileHeading;