import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Auth, Hub } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import doculensLogo from '../logos/logo-white.png';
import googleIcn from '../images/Google/google1.png';
import computerlogin from '../images/computer_login.svg';
import signUpImg from '../images/signUp.svg';
import { validateLogin } from '../validation/loginValidation';
import { loginUser, loginUserGoogle, registerUser } from '../actions/authAction';
import { validateSignUp } from '../validation/signUpValidation';
import { GoogleSignupContainer, GoogleSignin, GoogleLogo, GoogleText } from '../styles/style';
import '../styles/LoginPage.css';
import { NotificationManager } from 'react-notifications';

const LoginPage = () => {
    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [loginLoading, setLoginLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [checkbox, setCheckbox] = useState(false);
    const [signupLoading, setSignupLoading] = useState(false);
    const [orgName, setOrgName] = useState('');
    const [websiteURL, setWebsiteURL] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [signupEmail, setSignupEmail] = useState('');
    const [signupPassword, setSignupPassword] = useState('');
    const [signupConfirmPassword, setSignupConfirmPassword] = useState('');
    const auth = useSelector((state) => state.auth);
    const [isSignup, setIsSignup] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const errorDesc = history?.location.hash;
        const mergedAccountWithGoogleMsg = 'Google sign-in is merged with your existing account';
        if (errorDesc && errorDesc.split('+').join(' ').match(mergedAccountWithGoogleMsg)) {
            NotificationManager.info('Your non-Google account is merged with your Google account now')
        }

        // Auth listener to handle events like signIn, signOut, customOAuthState, etc.
        const authListener = ({ payload: { event, data } }) => {
            console.log('event', event);

            switch (event) {
                case 'signIn':
                    const idToken = data?.signInUserSession?.idToken?.jwtToken;
                    if (idToken) {
                        dispatch({
                            type: 'AUTH_LOADING',
                            payload: true,
                        });
                        dispatch(loginUserGoogle(idToken, history, history.location.state));
                    }
                    break;
                case 'signOut':
                    console.log('SIGN OUT');
                    break;
                case 'customOAuthState':
                    console.log('customOAuthState');
                    break;
                default:
                    console.log('EVENT: ', event, ' AND DATA : ', data);
                    break;
            }
        };

        Hub.listen('auth', authListener);

        return () => {
            Hub.remove('auth', authListener);
        };
    }, [dispatch, history]);

    useEffect(() => {
            setLoginLoading(auth?.authLoader);
    },[auth]);

    const handleLogin = async (e) => {
        e.preventDefault();
        const { errors, isValid } = validateLogin({ loginEmail, loginPassword, rememberMe });
        if (!isValid) {
            setFormErrors(errors);
        } else {
            setLoginLoading(true);
            const user = {
                username: loginEmail.toLowerCase().replace(/ /g, ''),
                loginPassword,
                rememberMe
            };
            if (rememberMe) {
                Auth.configure({ storage: localStorage });
                localStorage.setItem('rememberMe', true);
            } else {
                Auth.configure({ storage: sessionStorage });
                localStorage.setItem('rememberMe', false);
            }

            try {
                await dispatch(loginUser(user.username, user.loginPassword, history, history.location.state));
            } catch (error) {
                console.error('Login error:', error);
            } finally {
                setLoginLoading(false);
            }
        }
    };

    const googleSignIn = async () => {
        Auth.federatedSignIn({ provider: 'Google' });
        setLoginLoading(true);
        dispatch({
            type: 'AUTH_LOADING',
            payload: true,
        });
    };
    const handleSignup = (e) => {
        e.preventDefault();
        const { errors, isValid } = validateSignUp({ firstName, signupEmail, signupPassword, checkbox, signupConfirmPassword });
        setFormErrors(errors);

        if (!isValid || !checkbox) {
            return;
        }

        setSignupLoading(true);
        const newUser = {
            firstName: firstName,
            lastName: lastName,
            email: signupEmail,
            password: signupPassword,
            confirmPassword: signupConfirmPassword,
            langKey: 'en',
            login: 'randomstring',
            orgName,
            websiteURL
        };

        dispatch({
            type: 'AUTH_LOADING',
            payload: true,
        });
        dispatch(registerUser(newUser, history, 'signup'))
            .then((res) => {
                setSignupLoading(false);
                if (res !== 'Failure') {
                    setSignupLoading(false);
                }
                return;
            })
            .catch((error) => {
                setSignupLoading(false);

            });
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const isSignupDisabled = !firstName || !signupEmail || !signupPassword || !signupConfirmPassword || !checkbox;
    return (
        <div className="d-sm-flex d-flex flex-column justify-content-center container-box login-wrapper">
            <div className="login-form-container">
                <div className="login-form">
                    <img src={doculensLogo} alt="" className='doculensLogo' />
                    <div className="form-toggle">
                        {isSignup ? (
                            <h2>Create an Account</h2>
                        ) : (
                            <h2>Login to Your Account</h2>
                        )}
                        <p className="toggle-text">
                            {isSignup ? "Already have an account?" : "Don't have an account?"}
                            <span onClick={() => setIsSignup(!isSignup)} className="toggle-link">
                                {' '}
                                {isSignup ? "Login" : "Sign Up"}
                            </span>
                        </p>
                    </div>

                    {!isSignup ? (<form onSubmit={handleLogin}>
                        <div className="form-group">
                            <label>Email Address</label>
                            <input
                                type="email"
                                value={loginEmail}
                                onChange={(e) => setLoginEmail(e.target.value)}
                                placeholder="you@example.com"
                                required
                            />
                            {formErrors.loginEmail && <p className="error-text">{formErrors.loginEmail}</p>}
                        </div>

                        <div className="form-group password-field">
                            <label>Password</label>
                            <div className="password-input-container">
                                <input
                                    type={passwordVisible ? "text" : "password"}
                                    value={loginPassword}
                                    onChange={(e) => setLoginPassword(e.target.value)}
                                    placeholder="Enter 8 characters or more"
                                    required
                                />
                                <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                                    {passwordVisible ? (
                                        <i className="bi bi-eye-slash"></i>
                                    ) : (
                                        <i className="bi bi-eye"></i>
                                    )}
                                </span>
                            </div>
                            {formErrors.loginPassword && <p className="error-text">{formErrors.loginPassword}</p>}
                        </div>

                        <div className='termAndConditionContainer'>
                            <div className='rememberMeContainer'>
                                <label className="remember-me">
                                    <input
                                        type="checkbox"
                                        checked={rememberMe}
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                    />
                                    Remember me
                                </label>
                                <a href="/forgot-password" className="forgot-password">Forgot Password?</a>
                            </div>
                            {formErrors.checkbox && <p className="error-text">{formErrors.checkbox}</p>}
                        </div>

                        <button type="submit" className="signup-button" disabled={loginLoading}>
                            {loginLoading ? (
                                <div className="loader-container">
                                    <ThreeDots
                                        height="20"
                                        width="20"
                                        color="#FFFFFF"
                                        ariaLabel="loading"
                                    />
                                </div>
                            ) : (
                                'LOGIN'
                            )}
                        </button>

                        <div className="divider">
                            <span>or login with</span>
                        </div>

                        <div className="social-login">
                            {
                                process.env.REACT_APP_GOOGLE_SIGNIN_ALLOWED === 'yes' && (
                                    <GoogleSignupContainer>
                                        <GoogleSignin onClick={googleSignIn}>
                                            <GoogleLogo>
                                                <img alt="Google" src={googleIcn} />
                                            </GoogleLogo>
                                            <GoogleText>Continue with Google</GoogleText>
                                        </GoogleSignin>
                                    </GoogleSignupContainer>
                                )
                            }
                        </div>
                    </form>) : (
                        <form onSubmit={handleSignup} disabled={signupLoading || isSignupDisabled}>
                            <div className="form-group name-container">
                                <div className="name-input">
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        placeholder="First Name"
                                        required
                                    />
                                    {formErrors.firstName ? (
                                        <div className="is-invalid">{formErrors.firstName}</div>
                                    ) : null}
                                </div>

                                <div className="name-input">
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        placeholder="Last Name"
                                    />
                                    {formErrors.lastName && <p className="error-text">{formErrors.lastName}</p>}
                                </div>
                            </div>


                            <div className="form-group">
                                <label>Email Address</label>
                                <input
                                    type="email"
                                    value={signupEmail}
                                    onChange={(e) => setSignupEmail(e.target.value)}
                                    placeholder="you@example.com"
                                    required
                                />
                                {formErrors.signupEmail && <p className="error-text">{formErrors.signupEmail}</p>}
                            </div>

                            {/* Bootstrap Accordion for Password and Organization Fields */}
                            <div className="accordion" id="signupAccordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingPassword">
                                        <button
                                            className={`accordion-button ${isPasswordValid ? 'collapsed' : ''}`}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapsePassword"
                                            aria-expanded={!isPasswordValid}
                                            aria-controls="collapsePassword"
                                        >
                                            Password
                                        </button>
                                    </h2>
                                    <div
                                        id="collapsePassword"
                                        className={`accordion-collapse collapse ${isPasswordValid ? '' : 'show'}`}
                                        aria-labelledby="headingPassword"
                                        data-bs-parent="#signupAccordion"
                                    >
                                        <div className="accordion-body">
                                            <div className="form-group">
                                                <label>Password</label>
                                                <div className="input-group">
                                                    <input
                                                        type={showPassword ? "text" : "password"}
                                                        value={signupPassword}
                                                        onChange={(e) => setSignupPassword(e.target.value)}
                                                        placeholder="Enter 8 characters or more"
                                                        required
                                                        className="form-control"
                                                    />
                                                    <span
                                                        className="input-group-text"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {showPassword ? (
                                                            <i className="bi bi-eye-slash"></i>
                                                        ) : (
                                                            <i className="bi bi-eye"></i>
                                                        )}
                                                    </span>
                                                </div>
                                                {formErrors.signupPassword && <p className="error-text">{formErrors.signupPassword}</p>}
                                            </div>

                                            <div className="form-group">
                                                <label>Confirm Password</label>
                                                <div className="input-group">
                                                    <input
                                                        type={showConfirmPassword ? "text" : "password"}
                                                        value={signupConfirmPassword}
                                                        onChange={(e) => setSignupConfirmPassword(e.target.value)}
                                                        placeholder="Confirm your password"
                                                        required
                                                        className="form-control"
                                                    />
                                                    <span
                                                        className="input-group-text"
                                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {showConfirmPassword ? (
                                                            <i className="bi bi-eye-slash"></i>
                                                        ) : (
                                                            <i className="bi bi-eye"></i>
                                                        )}
                                                    </span>
                                                </div>
                                                {formErrors.signupConfirmPassword && <p className="error-text">{formErrors.signupConfirmPassword}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* 
                            {isPasswordValid && (
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOrg">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOrg"
                                            aria-expanded="true"
                                            aria-controls="collapseOrg"
                                        >
                                            Organization Details
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOrg"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOrg"
                                        data-bs-parent="#signupAccordion"
                                    >
                                        <div className="accordion-body">
                                            <div className="form-group">
                                                <label>Organization Name</label>
                                                <input
                                                    type="text"
                                                    value={orgName}
                                                    onChange={(e) => setOrgName(e.target.value)}
                                                    placeholder="Your Organization Name"
                                                    required
                                                />
                                                {formErrors.orgName && <p className="error-text">{formErrors.orgName}</p>}
                                            </div>

                                            <div className="form-group">
                                                <label>Website URL</label>
                                                <input
                                                    type="url"
                                                    value={websiteURL}
                                                    onChange={(e) => setWebsiteURL(e.target.value)}
                                                    placeholder="https://yourwebsite.com"
                                                    required
                                                />
                                                {formErrors.websiteURL && <p className="error-text">{formErrors.websiteURL}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )} */}
                            </div>

                            <div className='termAndConditionContainer'>
                                <div>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={checkbox}
                                            onChange={(e) => setCheckbox(e.target.checked)}
                                            className='termsCheckbox'
                                        />
                                            <span className="font-24-letterSpacing-40 pl-2">
                                                I accept &nbsp;
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}
                                                    className="counts-light-blue-color"
                                                >
                                                    Terms of Service
                                                    {' '}
                                                </a>
                                                and &nbsp;
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
                                                    className="counts-light-blue-color"
                                                >
                                                    Privacy Policy
                                                    {' '}
                                                </a>
                                                .
                                            </span>
                                    </label>
                                </div>
                                {formErrors.checkbox && <p className="error-text">{formErrors.checkbox}</p>}
                            </div>

                            <button type="submit" className="signup-button" disabled={signupLoading}>
                                {signupLoading ? (
                                    <div className="loader-container">
                                        <ThreeDots
                                            height="20"
                                            width="20"
                                            color="#FFFFFF"
                                            ariaLabel="loading"
                                        />
                                    </div>
                                ) : (
                                    'SIGN UP'
                                )}
                            </button>
                            <div className="divider" style={{ margin: '10px 0' }}>
                                <span>or login with</span>
                            </div>
                            <div className="social-login">
                                {
                                    process.env.REACT_APP_GOOGLE_SIGNIN_ALLOWED === 'yes' && (
                                        <GoogleSignupContainer>
                                            <GoogleSignin onClick={googleSignIn}>
                                                <GoogleLogo>
                                                    <img alt="Google" src={googleIcn} />
                                                </GoogleLogo>
                                                <GoogleText>Continue with Google</GoogleText>
                                            </GoogleSignin>
                                        </GoogleSignupContainer>
                                    )
                                }
                            </div>
                        </form>
                    )}
                </div>

                <div className="login-image">
                    <div className="image-placeholder">
                        <img src={ !isSignup ? computerlogin : signUpImg} alt="login-img" className='loginPageImg' />
                    </div>
                    <p className='doculensText'>Gain the insight and control you need to close more deals</p>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
