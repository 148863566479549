import axios from 'axios';
import { NotificationManager } from 'react-notifications';

export const getAssistantsByGetCallSolution = () => dispatch => {
    return axios
        .get(process.env.REACT_APP_API_URL_FOR_SOLUTION + '/api/virtual-assistents/my-assistant-statistics')
        .then(res => {
            dispatch({
                type: 'ASSISTANTS',
                payload: res.data
            });
            return res.data;
        })
        .catch(err => {
            dispatch({
                type: 'ASSISTANTS_ERROR',
                payload: err.message
            });
            throw err;
        });
};

export const assignAssistantToUser = (user) => dispatch => {
    axios
        .put(process.env.REACT_APP_API_URL_FOR_SOLUTION + '/api/management/org/virtual-assistents/assign', user)
        .then()
        .catch(err => {
            NotificationManager.error("Failed to assisgn assitant to new user")
        })
}