import { combineReducers } from 'redux';
import authReducer from './authReducer';
import userReducer from './userReducer';
import creditReducer from './creditReducer';
import documentReducer from './documentReducer';
import viewReducer from './viewReducer';
import organizationReducer from './organizationReducer';
import notificationPreferencesReducer from './notificationPreferencesReducer';
import planReducer from './planReducer';
import assistantsReducer from './assistantsReducer';

export default combineReducers({
    authReducer,
    userReducer,
    creditReducer,
    documentReducer,
    planReducer,
    viewReducer,
    organizationReducer,
    notificationPreferencesReducer,
    assistantsReducer
});