import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import Modal from 'react-modal';
import { useDispatch } from "react-redux";
import { getUploadPresignedURL, pingDocumentCreated, getAllDocuments } from '../actions/documentAction';
import { NotificationManager } from 'react-notifications';
import '../styles/UploadModal.css'
import { useMediaQuery } from 'react-responsive'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
  },
};

const UploadModal = (props) => {
  const dispatch = useDispatch();
  // const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null)
  const [uploadStatusText, setUploadStatusText] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [fileProcessing, setFileProcessing] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [intervalId, setIntervalId] = useState(null)
  const [documentId, setDocumentId] = useState(null)

  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles[0].size > 10000000) {
      setError("Size of pdf should be less than 10MB")
    }
    setFile(acceptedFiles[0])
    setError(null)
  }, [])

  const onDropRejected = useCallback(rejectedFiles => {
    if (rejectedFiles.length > 1) {
      setError("Only one file is accepted")
    }
    else if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
      setError("Only pdf file is accepted")
    } else {
      setError("File cannot be uploaded")
    }
  })

  const onDocumentLoad = ({ numPages }) => {
    if (numPages > 200) {
      setError("PDF with more than 200 pages is not allowed.")
    }
  }

  // useEffect(() => {
  //   if (props.isOpen) {
  //     setIsOpen(true)
  //   } else {
  //     setIsOpen(false)
  //   }
  // }, [props.isOpen])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, accept: 'application/pdf', onDropRejected })

  // function openModal() {
  //   setIsOpen(true);
  // }

  function closeModal() {
    // setIsOpen(false);
    setFile(null);
    setError(null);
    setUploadStatusText(null);
    setFileUploading(false);
    setFileProcessing(false);
    setFileUploaded(false);
    setDocumentId(null);
  }

  function uploadFile(e) {
    e.stopPropagation();
    setUploadStatusText("Uploading... ⏳");
    setFileUploading(true);

    getUploadPresignedURL(file)
      .then((res) => {
        setDocumentId(res.data.details.document_id);
        const req = new XMLHttpRequest();
        req.open("PUT", res.data.details.url);

        req.onreadystatechange = function () {
          if (this.readyState === 4) {
            if (this.status === 200) {
              // Success block
              setFileUploading(false);
              setFileProcessing(true);
              NotificationManager.success("PDF Uploaded Successfully");

              // Call getAllDocuments only after successful upload
              getAllDocuments();

              setUploadStatusText("PDF Uploaded Successfully ✅. Processing... ⏳");
            } else {
              // Handle error if needed
              setUploadStatusText("Upload failed ❌, please contact support.");
              setFileUploading(false);
            }
          }
        };

        req.send(file);
      })
      .catch((err) => {
        setUploadStatusText("Upload failed ❌, please contact support.");
        setFileUploading(false);
      });

    // closeModal(); // Uncomment if you want to close the modal
  }


  // useEffect(() => {
  //   if(fileUploading) {
  //     window.addEventListener('beforeunload', leaveSite)
  //   } else {
  //     window.removeEventListener('beforeunload', leaveSite)
  //   }
  // }, [fileUploading])

  const leaveSite = (e) => {
    e.preventDefault()
    e.returnValue = 'File is still being uploaded. Are you sure you want to leave?';
    return 'File is still being uploaded. Are you sure you want to leave?';
  }

  useEffect(() => {
    if (fileProcessing) {
      let intervalId = setInterval(ping, 3000)
      setIntervalId(intervalId)
    }
  }, [fileProcessing])

  useEffect(() => {
    if (fileUploaded) {
      clearInterval(intervalId)
      dispatch({
        type: 'DOCUMENTS_LOADING',
        payload: { document_loader: true }
      });
      dispatch(getAllDocuments());
      setFileProcessing(false)
      NotificationManager.success("PDF Processed Successfully")
      closeModal()
    }
  }, [fileUploaded])

  const ping = () => {
    pingDocumentCreated(documentId).then(res => {
      if (res.data.details !== null) {
        setFileUploaded(true)
      }
    })
  }
  return (
    <div>
      {/* <button onClick={openModal} className='floating-button'><i className="bi bi-plus-lg"></i></button> */}
      {/* <Modal
        isOpen={isOpen}
        style={customStyles}
        contentLabel="Example Modal"
        onRequestClose={closeModal}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={!fileUploading}
        shouldCloseOnEsc={!fileUploading}
      > */}
      <div {...getRootProps()} className='col-12'>
        <div className='inner-box' style={{ height: !isMobile && '10vw', maxHeight: !isMobile && '12vw', justifyContent: !isMobile && 'center', cursor: 'pointer' }}>
          <input {...getInputProps()} className='inner-box' />
          <div className='upload-icon p-2'>
            <i className="bi bi-file-earmark-pdf"></i>
          </div>
          {
            file ?
              <p className='upload-text'>{file.name}</p> :
              isMobile ?
                <p className='upload-text'>Click here to upload</p> :

                <p className='upload-text'>Drag and drop your PDF file here, or click to select a file to upload.</p>

          }
          {
            error ?
              <p className='upload-error-text'>{error}</p> :
              null
          }
          {
            file && !error && !uploadStatusText ?
              <button onClick={uploadFile} className='white-button-document mb-4'>Upload</button> :
              null
          }
          {
            uploadStatusText ?
              <p className='upload-text'>{uploadStatusText}</p> :
              null
          }
        </div>
        {
          file ?
            <div className='d-none'><Document file={file} onLoadSuccess={onDocumentLoad} /></div> :
            (null)
        }

      </div>
      {/* </Modal> */}
    </div>
  );
}

export default UploadModal;