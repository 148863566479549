import React from 'react';
import '../styles/Footer.css'
import '../styles/Common.css'

const Footer = (props) => {
  return (
    <React.Fragment>
      {/* <footer className='footer'>
        <div className='container-fluid text-center footer'>
          <p className='footer-text'>Copyright © 2022 by DocuLens. All Rights Reserved.</p>
        </div>
      </footer> */}
    </React.Fragment>
  )
}

export default Footer;