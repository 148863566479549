import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import DocumentHeading from './DocumentHeading';
import ViewsTable from './ViewsTable'
import GraphView from './GraphView';
import Footer from './Footer';
import { useParams } from "react-router-dom";
import { getDocumentById } from '../actions/documentAction';
import UploadModal from './UploadModal'
import { getAllViewsByDocumentId } from '../actions/viewAction';
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from 'react-responsive';

const Views = () => {
  const [openModal, setOpenModal] = useState(false);
  const { documentId } = useParams();
  const views = useSelector(state => state.viewReducer.documentId);
  const [graphData, setGraphData] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedView, setSelectedView] = useState(null);

  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });

  useEffect(() => {
    if (documentId !== null && documentId !== undefined) {
      dispatch(getDocumentById(documentId));
      dispatch(getAllViewsByDocumentId(documentId));
    }
  }, [documentId]);

  useEffect(() => {
    if (views !== null && views !== undefined) {
      setLoading(false);
      setGraphData((views[0] || {}).views);
      setSelectedView((views[0] || {}).email_id);
    } else {
      setLoading(true);
    }

  }, [views]);

  const handleOnChangeSelectedView = (email_id) => {
    setGraphData(((views || []).find(x => x.email_id === email_id) || {}).views);
    setSelectedView(email_id);
  };

  return (
    <React.Fragment>
      <Navbar />
      <div className='container-fluid'>
        <div className='row'>
          <div className={isMobile ? "col-12 pe-0 overflow-auto full-length pt-5" : "col-8 pe-0 overflow-auto full-length pt-5"}>
            <DocumentHeading documentId={documentId} />
            <ViewsTable loading={loading} views={views} selectedView={selectedView} onChangeSelectedView={handleOnChangeSelectedView} graphData={graphData} />
          </div>
          {!isMobile && (
            <div className='col-4 ps-0 overflow-auto full-length grey-background'>
              {loading ? (
                <div>
                  {/* <div className="shimmer" style={{ height: '20px', width: '100%' }}></div> */}
                  {/* <div className="shimmer" style={{ height: '3vw', width: '100%' }}></div> */}
                  <div className="shimmer" style={{ height: '2.5vw', width: '100%' }}></div>
                  <div className="shimmer" style={{ height: '2.5vw', width: '100%' }}></div>
                </div>
              ) : (
                <GraphView graphData={graphData} loading={loading} emailId={selectedView} />
              )}
            </div>
          )}
          {/* <UploadModal isOpen={openModal} closeModal={e => setOpenModal(false)} /> */}
        </div>
      </div>
      {!isMobile && <Footer />}
    </React.Fragment>
  );
}

export default Views;
