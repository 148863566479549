import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import '../styles/ForgotPassword.css';
import doculensLogo from '../logos/logo-white.png';
import forgotPasswordImg from '../images/Forgot_password.svg';
import resetPasswordImg from '../images/Reset_password.svg';
import { NotificationManager } from 'react-notifications';
import { validateResetPasswordSubmit } from '../validation/validateResetPasswordSubmit';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { userLogout } from '../actions/authAction';

const ForgotPassword = (props) => {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const dispatch = useDispatch();

    const [step, setStep] = useState(() => {
        const savedStep = localStorage.getItem('forgotPasswordStep');
        return savedStep ? JSON.parse(savedStep) : 1;
    });

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        localStorage.setItem('forgotPasswordStep', JSON.stringify(step));
    }, [step]);

    useEffect(() => {
        const savedEmail = localStorage.getItem('forgotPasswordEmail');
        if (savedEmail) {
            setEmail(savedEmail);
        } else {
            setStep(1);
        }
    }, []);

    const handleSendCode = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await Auth.forgotPassword(email);
            localStorage.setItem('forgotPasswordEmail', email);
            setStep(2);
        } catch (error) {
            setFormErrors({ email: error.message });
        } finally {
            setLoading(false);
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();

        // Validate the inputs using the validateResetPasswordSubmit function
        const { errors, isValid } = validateResetPasswordSubmit({
            code,
            newPassword,
            confirmPassword
        });
        // If validation fails, set form errors and stop the process
        if (!isValid) {
            setFormErrors(errors);
            return;
        }

        setLoading(true);
        try {
            await Auth.forgotPasswordSubmit(email, code, newPassword);
            NotificationManager.success("Password reset successfully");
            localStorage.removeItem('forgotPasswordEmail');
            localStorage.removeItem('forgotPasswordStep');
            history.push('/');
        } catch (error) {
            setFormErrors({ newPassword: error.message });
        } finally {
            setLoading(false);
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    const onClickBackToLogin = () => {
        localStorage.removeItem('forgotPasswordEmail');
        localStorage.removeItem('forgotPasswordStep');
        dispatch(userLogout());
    };

    return (
        <div className="forgot-password-container">
            <div className="forgot-password-content">
                <div className="forgot-password-form-section">
                    <img src={doculensLogo} alt="" className='doculensLogo' />
                    <h2>{location.pathname.includes('/reset-password') ? 'Reset Your Password' : 'Forgot Your Password'}</h2>
                    {step === 1 ? (
                        <form onSubmit={handleSendCode}>
                            <div className="form-group">
                                <label>Email Address</label>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="you@example.com"
                                    required
                                />
                                <p style={{
                                    margin: '0.5rem 0',
                                    color: '#99acc2'
                                }}>If you use "Sign-in with Google" (Social login) to login in Doculens then password cannot be reset from here.</p>
                                {formErrors.email && <p className="error-text">{formErrors.email}</p>}
                            </div>
                            <button type="submit" className="submit-button" disabled={loading}>
                                {loading ? (
                                    <div className="loader-container">
                                        <ThreeDots height="20" width="20" color="#FFFFFF" ariaLabel="loading" />
                                    </div>
                                ) : (
                                    'Send Code'
                                )}
                            </button>
                            <div className='mt-2' style={{ textAlign: 'center' }}>
                                <Link to='/documents'>
                                    Cancel
                                </Link>
                            </div>
                        </form>
                    ) : (
                        <form onSubmit={handleResetPassword}>
                            <div className="form-group">
                                <label>Verification Code</label>
                                <input
                                    type="text"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    placeholder="Enter the verification code you received"
                                    required
                                />
                                {formErrors.code && <p className="error-text">{formErrors.code}</p>}
                            </div>

                            <div className="form-group password-input-container">
                                <label>New Password</label>
                                <div className='password-input-container'>
                                    <input
                                        type={passwordVisible ? "text" : "password"}
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        placeholder="Enter your new password"
                                        required
                                    />
                                    <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                                        {passwordVisible ? (
                                            <i className="bi bi-eye-slash"></i>
                                        ) : (
                                            <i className="bi bi-eye"></i>
                                        )}
                                    </span>
                                </div>
                                {formErrors.fpSubmitPassword && <p className="error-text">{formErrors.fpSubmitPassword}</p>}
                            </div>

                            <div className="form-group">
                                <label>Confirm Password</label>
                                <div className='password-input-container'>
                                    <input
                                        type={confirmPasswordVisible ? "text" : "password"}
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="Confirm your new password"
                                        required
                                    />
                                    <span className="password-toggle-icon" onClick={toggleConfirmPasswordVisibility}>
                                        {confirmPasswordVisible ? (
                                            <i className="bi bi-eye-slash"></i>
                                        ) : (
                                            <i className="bi bi-eye"></i>
                                        )}
                                    </span>
                                </div>
                                {formErrors.confirmPassword && <p className="error-text">{formErrors.confirmPassword}</p>}
                            </div>

                            <button type="submit" className="submit-button" disabled={loading}>
                                {loading ? (
                                    <div className="loader-container">
                                        <ThreeDots height="20" width="20" color="#FFFFFF" ariaLabel="loading" />
                                    </div>
                                ) : (
                                    'Reset Password'
                                )}
                            </button>
                            <div className='mt-2' style={{ textAlign: 'center' }}>
                                <Link to='/' onClick={onClickBackToLogin}>
                                    Back to Login
                                </Link>
                            </div>
                        </form>
                    )}
                </div>

                <div className="forgot-password-image-section">
                    <img src={location.pathname.includes('/reset-password') ? resetPasswordImg : forgotPasswordImg} alt="Forgot Password" />
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
