const userReducer = (state = {}, action) => {
    // if (!('email' in state) ) {
    //     state = JSON.parse(localStorage.getItem('user')) || {}
    // }
    switch (action.type) {
        case 'DETAILS_FROM_AUTH_SIGNIN':
            return {
                ...state,
                ...action.payload
            }
        case 'USER_NAME':
            return {
                ...state,
                name: action.payload
            }
        case 'USER_SIGNOUT':
            return {}
        case 'DETAILS_FORM_USER_API':
            return {
                ...state,
                ...action.payload
            }
        case 'CURRENT_USER_DATA_FROM_SOLUTION':
            return {
                ...state,
                userFromSolution: action.payload
            }      
        case 'USER_DETAILS_LOADER':
            return {
                ...state,
                userLoader: action.payload
            }

        case 'INVITE_USER_SUCCESS':
            return {
                ...state,
                invitedUserDetails: action.payload
            }

        case 'INVITE_USER_LOADER':
            return {
                ...state,
                invitedUserLoader: action.payload
            }
            
        default:
            return state
    }
}

export default userReducer;