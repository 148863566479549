import React from 'react';
import { useHistory } from 'react-router-dom';
import '../styles/DocumentHeading.css'
import '../styles/Common.css'
import { useDispatch, useSelector } from "react-redux";
import { getDownloadDocumentLink } from '../actions/documentAction';
import { exportAllViews } from '../actions/viewAction';
import { deleteDocumentById } from '../actions/documentAction';
import { NotificationManager } from 'react-notifications';
import { b64EncodeUnicode } from '../utils';
import { useMediaQuery } from 'react-responsive';

const DocumentHeading = (props) => {
  const { documentId } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const documents = useSelector(state => state.documentReducer);
  const views = useSelector(state => state.viewReducer.documentId);

  const downloadFile = () => {
    dispatch(getDownloadDocumentLink(b64EncodeUnicode((documents.selected_document || {}).file_key))).then(res => {
      const element = document.createElement("a");
      element.href = res;
      element.target = "_blank"
      element.download = "file.pdf";
      document.body.appendChild(element);
      element.click();
    })
  }

  const exportCSV = () => {
    dispatch(exportAllViews((documents.selected_document || {}).id))
  }

  const deleteDocument = () => {
    dispatch(deleteDocumentById((documents.selected_document || {}).id)).then(e => history.push('/'))
  }

  const copyLink = () => {
    navigator.clipboard.writeText(process.env.REACT_APP_BASE_URL + "/document/" + b64EncodeUnicode((documents.selected_document || {}).file_key) + '?email=')
    NotificationManager.success("Link copied to clipboard")
  }

  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })

  return (
    <React.Fragment>
      {isMobile ?
        <div className='container-fluid pt-4'>
          <div className='box'>
            <div className='d-flex mobile-box-1'>
              {
                (documents.selected_document || {}).processed ?
                  <img className='profile-user-image me-4' src={'https://doculens-documents-thumbnail-' + process.env.REACT_APP_AWS_ACCOUNT_ID + '-' + process.env.REACT_APP_STAGE + '.s3.amazonaws.com/' + encodeURI(((documents.selected_document || {}).file_key || "").replace('.pdf', '.jpg')) || ""} alt='pdf'></img>
                  : null
              }
              <p className='mobile-name'>{(documents.selected_document || {}).name}</p>

            </div>
            <div className='row mt-4 details-box'>
              <div className='col-12 extras-wrapper p-0'>
                <div className='col-4'>
                  <div className='d-flex justify-content-start extra-value'>
                    <div className='value-point-description flex-column'>
                      <p className='value-point-heading'>Links</p>
                      <p className='value-point-number text-center'>1</p>
                    </div>
                  </div>
                </div>
                <div className='col-4'>
                  <div className='d-flex justify-content-start extra-value'>
                    {!isMobile &&
                      <div className='value-point-div light-purple-color-background d-flex align-items-center justify-content-center'>
                        <i className="bi bi-eye-fill value-point-icon"></i>
                      </div>
                    }
                    <div className='value-point-description flex-column' >
                      <p className='value-point-heading'>Views</p>
                      <p className='value-point-number text-center'>{(views || []).map(i => i.total_views).reduce((a, b) => a + b, 0)}</p>
                    </div>
                  </div>
                </div>
                <div className='col-4'>
                  <div className='d-flex justify-content-start extra-value'>

                    <div className='value-point-description flex-column'>
                      <p className='value-point-heading'>Unique</p>
                      <p className='value-point-number text-center'>{(views || []).length}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-5'>
                <div className='d-flex justify-content-evenly flex-row button-wrapper-mobile'>
                  {/* <button className='dark-blue-button'>Preview</button> */}
                  <button className='dark-purple-button btn d-flex align-items-center justify-content-center' onClick={downloadFile}><i className="bi bi-file-earmark-arrow-down-fill dark-purple-color"></i></button>
                  <button className='dark-blue-button btn d-flex align-items-center justify-content-center' onClick={exportCSV}><i className="bi bi-file-spreadsheet-fill"></i></button>
                  {/* <button className='light-purple-button btn d-flex align-items-center justify-content-center' onClick={deleteDocument}><i className="bi bi-trash-fill"></i></button> */}
                  {/* <button className='light-purple-button'>Delete</button> */}
                  {
                    (documents.selected_document || {}).processed ?
                      <button className='light-blue-button btn' onClick={copyLink}><i className="bi bi-share-fill  dark-purple-color"></i></button> :
                      <button className='light-blue-button btn'>Processing...</button>
                  }
                  {/* <button className='light-blue-button' onClick={copyLink}>Share</button> */}
                  {/* <div className="dropdown">
                            <button className='purple-button-document dropdown-toggle' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">More</button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" href="#">Create Link</a></li>
                              <li><a className="dropdown-item" href="#">Re-Upload</a></li>
                            </ul>
                          </div> */}
                </div>
              </div>


            </div>


          </div>
        </div>

        :
        <div className='container-fluid division right-border'>
          <div className='container pt-5'>
            <div className='row pb-3'>
              <div className='col-10'>
                <div className='d-flex justify-content-start'>
                  {
                    (documents.selected_document || {}).processed ?
                      <img className='profile-user-image me-4' src={'https://doculens-documents-thumbnail-' + process.env.REACT_APP_AWS_ACCOUNT_ID + '-' + process.env.REACT_APP_STAGE + '.s3.amazonaws.com/' + encodeURI(((documents.selected_document || {}).file_key || "").replace('.pdf', '.jpg')) || ""} alt='pdf'></img>
                      : null
                  }
                  <div className='ms-4 align-self-center document-heading-icons'>
                    <p className='profile_user_name'>{(documents.selected_document || {}).name}</p>
                    <div className='row mt-4'>
                      <div className='col-4'>
                        <div className='d-flex justify-content-start' style={{ gap: '10px' }}>
                          <div className='value-point-div light-blue-color-background d-flex align-items-center justify-content-center'>
                            <i className="bi bi-link value-point-icon"></i>
                          </div>
                          <div className='value-point-description'>
                            <p className='value-point-heading'>Links</p>
                            <p className='value-point-number text-center'>1</p>
                          </div>
                        </div>
                      </div>
                      <div className='col-4'>
                        <div className='d-flex justify-content-start' style={{ gap: '10px' }}>
                          <div className='value-point-div light-purple-color-background d-flex align-items-center justify-content-center'>
                            <i className="bi bi-eye-fill value-point-icon"></i>
                          </div>
                          <div className='value-point-description'>
                            <p className='value-point-heading'>Views</p>
                            <p className='value-point-number text-center'>{(views || []).map(i => i.total_views).reduce((a, b) => a + b, 0)}</p>
                          </div>
                        </div>
                      </div>
                      <div className='col-4'>
                        <div className='d-flex justify-content-start' style={{ gap: '10px' }}>
                          <div className='value-point-div dark-blue-color-background d-flex align-items-center justify-content-center'>
                            <i className="bi bi-person-check-fill value-point-icon"></i>
                          </div>
                          <div className='value-point-description'>
                            <p className='value-point-heading'>Unique</p>
                            <p className='value-point-number text-center'>{(views || []).length}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-2'>
                <div className='d-flex justify-content-evenly ms-2 flex-column'>
                  {/* <button className='dark-blue-button'>Preview</button> */}
                  <button className='dark-purple-button' onClick={downloadFile}>Download</button>
                  <button className='dark-blue-button' onClick={exportCSV}>Export</button>
                  <button className='light-purple-button' onClick={deleteDocument}>Delete</button>
                  {
                    (documents.selected_document || {}).processed ?
                      <button className='light-blue-button' onClick={copyLink}>Share</button> :
                      <button className='light-blue-button'>Processing...</button>
                  }
                  {/* <button className='light-blue-button' onClick={copyLink}>Share</button> */}
                  {/* <div className="dropdown">
                      <button className='purple-button-document dropdown-toggle' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">More</button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a className="dropdown-item" href="#">Create Link</a></li>
                        <li><a className="dropdown-item" href="#">Re-Upload</a></li>
                      </ul>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  )
}

export default DocumentHeading;