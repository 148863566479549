import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Logo from '../logos/logo-white.png';
import '../styles/Navbar.css';
import '../styles/Common.css';
import { useMediaQuery } from 'react-responsive';
import { Tooltip } from 'antd';
import { userLogout } from '../actions/authAction';

const Navbar = (props) => {
  const user = useSelector(state => state.userReducer);
  const location = useLocation();
  const auth = useSelector(state => state.authReducer);
  const dispatch = useDispatch();

  const [showLogoutBtn, setShowLogout] = useState(null);
  useEffect(() => {    
    setShowLogout(auth.isAuthenticated)
  }, [auth]);

  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const onLogoutClick = (e) => {
    localStorage.removeItem('featuresToCover');
    localStorage.removeItem('keywordsToInclude');
    dispatch(userLogout());
  };

  return (
    <React.Fragment>
      <nav className='navbar'>
        <div className='container-fluid'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-3'>
                <Link to='/documents'>
                  <img className='logo' src={Logo} alt='logo'></img>
                </Link>
              </div>
              <div className='col-9 d-flex justify-content-end align-items-center'>
                {
                  Object.keys(user).length !== 0 ?
                    <div className='d-flex justify-content-end align-items-center' style={{ marginTop: isMobile && '1vw' }}>
                      {/* Show user info and dropdown only on mobile */}

                      {/* Show Home and Setting icons only on non-mobile */}
                      {/* {!isMobile && */}
                      {
                        location.pathname !== '/plan' &&
                        <Link to='/plan'>
                          <Tooltip title="Plan">
                              <i className="bi bi-wallet2 profile-heading-icon navbar-icons" style={{ margin: !isMobile && '0 0.7vw' }} />
                          </Tooltip>
                        </Link>
                      }
                      <>
                        {location.pathname !== '/documents' && (
                          <Link to='/documents'>
                            <Tooltip title="Documents">
                              <i className="bi bi-file-pdf profile-heading-icon navbar-icons" />
                            </Tooltip>
                          </Link>
                        )}
                        {(location.pathname !== '/profile' && location.pathname !== '/organization' && location.pathname !== '/notification') &&
                          <Link to='/profile'>
                            <Tooltip title="Settings">
                              <i className="bi bi-gear profile-heading-icon navbar-icons" />
                            </Tooltip>
                          </Link>
                        }
                        {showLogoutBtn && <Tooltip title="Logout">
                          <i className="bi bi-box-arrow-right profile-heading-icon navbar-icons" onClick={onLogoutClick}></i>
                        </Tooltip>}
                      </>
                      {/* } */}
                    </div>
                    :
                    <>
                      {showLogoutBtn && <Tooltip title="Logout">
                        <i className="bi bi-box-arrow-right profile-heading-icon navbar-icons" onClick={onLogoutClick}></i>
                      </Tooltip>}</>
                }

              </div>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment >
  );
}

export default Navbar;
