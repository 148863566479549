const planReducer = (state = {}, action) => {
    switch (action.type) {
        case 'DOCULENS_PLANS':
            return {
                ...state,
                doculens_plans: action.payload
            }
        case 'DOCULENS_PLANS_LOADER':
            return {
                ...state,
                plan_loader: action.payload
            }
        case 'SELECT_PLAN':
            return {
                ...state,
                userPlans: action.payload
            }
        case 'SAVE_PLAN_SUCCESS':
            return {
                ...state,
                userPlanSave: action.payload
            }
        case 'ACTIVE_PLAN':
            return {
                ...state,
                activePlan: action.payload
            }
        default:
            return state
    }
}

export default planReducer;