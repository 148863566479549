import isEmpty from "./is-empty";
import { validatePasswordFormation, validateConfirmPassword } from "../utils";

export const validateCompletePassword = data => {
    let errors = {};

    data.newPassword = !isEmpty(data.newPassword) ? data.newPassword : "";
    data.confirmNewPassword = !isEmpty(data.confirmNewPassword) ? data.confirmNewPassword : "";

    validatePasswordFormation(errors, data, 'newPassword', 'newPassword');
    validateConfirmPassword(errors, data, 'confirmNewPassword', 'confirmNewPassword', 'newPassword');

    return {
        errors,
        isValid: isEmpty(errors)
    };
};
