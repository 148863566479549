import isEmpty from "./is-empty";
import { validateAcceptedTnC, validateConfirmPassword, validatePasswordFormation, validateUserEmail, validateUserFirstName } from "../utils";

export const validateSignUp = data => {
    let errors = {};

    data.signupFname = !isEmpty(data.signupFname) ? data.signupFname : "";
    data.signupEmail = !isEmpty(data.signupEmail) ? data.signupEmail : "";
    data.signupPassword = !isEmpty(data.signupPassword)
        ? data.signupPassword
        : "";
    data.signupConfirmPassword = !isEmpty(data.signupConfirmPassword)
        ? data.signupConfirmPassword
        : "";

    validateUserFirstName(errors, data, 'firstName', 'firstName');
    validateUserEmail(errors, data, 'signupEmail', 'signupEmail');
    validatePasswordFormation(errors, data, 'signupPassword', 'signupPassword');
    validateAcceptedTnC(errors, data, 'checkbox', 'checkbox');
    validateConfirmPassword(errors, data, 'signupConfirmPassword', 'signupConfirmPassword', 'signupPassword');

    return {
        errors,
        isValid: isEmpty(errors)
    };
};
