import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { getAllDocuments } from './documentAction';
import { getUser } from './userAction'

export const getOrganizationByPostCall = () => dispatch => {
    return axios
        .post(`${process.env.REACT_APP_API_URL}/organizations`)
        .then(res => {
            dispatch({
                type: 'DETAILS_FROM_ORGANIZATION_API',
                payload: res.data.details,
            });
            // Dispatch additional actions after success
            dispatch(getUser());
            dispatch(getAllDocuments());
            return Promise.resolve(res);  // Return a resolved promise
        })
        .catch(err => {
            console.error('Failed to get organization details:', err);
            NotificationManager.error('Failed to get organization details');
            return Promise.reject(err);  // Return a rejected promise
        });
};
export const getOrganizationByGetCall = () => dispatch => {
    axios
        .get(process.env.REACT_APP_API_URL + '/organizations')
        .then(res => {
            dispatch({
                type: 'DETAILS_FROM_ORGANIZATION_API',
                payload: res.data.details
            })
            // dispatch({
            //     type: 'SELECTED_DOCUMENT',
            //     payload: { document_loader: true }
            // });
            // dispatch({
            //     type: 'DOCUMENTS_LOADING',
            //     payload: { document_loader: true }
            // });
            // dispatch(getAllDocuments());
            // dispatch(getUser());
        })
        .catch(err => {
            dispatch(getOrganizationByPostCall())
        })
}

export const updateOrganization = (organization) => dispatch => {
    axios
        .put(process.env.REACT_APP_API_URL + '/organizations', organization)
        .then(res => {
            dispatch({
                type: 'DETAILS_FROM_ORGANIZATION_API',
                payload: res.data.details
            })
            dispatch({
                type: 'ORGANIZATION_LOADER',
                payload: false
            })
            NotificationManager.success("Organization details updated successfully")
        })
        .catch(err => {
            dispatch({
                type: 'ORGANIZATION_LOADER',
                payload: false
            })
            NotificationManager.error("Failed to update organization")
        })
}

export const addOrganization = (organization) => dispatch => {
    return axios
        .post(process.env.REACT_APP_API_URL_FOR_SOLUTION + '/api/organisations/onboard_details', organization)
        .then(response => {
            dispatch({
                type: 'ORGANIZATION_LOADER',
                payload: false
            });
            NotificationManager.success("Organization details added successfully");
            return response;
        })
        .catch(err => {
            dispatch({
                type: 'ORGANIZATION_LOADER',
                payload: false
            });

            // Check if the error has a response
            if (err.response) {
                // Extract the error message from the response
                const errorMessage = err.response.data.title || "An error occurred. Please try again.";
                NotificationManager.error(errorMessage); // Display the error message
            } else {
                // Handle network errors or other errors without a response
                NotificationManager.error("Network error. Please try again.");
            }

            throw err; // Re-throw the error if you want to handle it further up
        });
};

export const getOrganizationByGetCallSolution = () => dispatch => {
    axios
        .get(process.env.REACT_APP_API_URL_FOR_SOLUTION + '/api/organisations/my')
        .then(res => {
            dispatch({
                type: 'DETAILS_FROM_SOLUTION_ORGANIZATION_API',
                payload: res.data
            })
            // dispatch({
            //     type: 'SELECTED_DOCUMENT',
            //     payload: { document_loader: true }
            // });
            // dispatch({
            //     type: 'DOCUMENTS_LOADING',
            //     payload: { document_loader: true }
            // });
            // dispatch(getAllDocuments());
            // dispatch(getUser());
        })
        .catch(err => {
        })
}

export const getOrganizationOnboardStatusByGetCallSolution = () => dispatch => {
    axios
        .get(process.env.REACT_APP_API_URL_FOR_SOLUTION + '/api/organisations/onboard_status')
        .then(res => {
            dispatch({
                type: 'DETAILS_FROM_ONBOARD_STATUS',
                payload: res.data
            })
        })
        .catch(err => {
        })
}

export const updateOrganizationCallSolution = (organization) => dispatch => {
    axios
        .put(process.env.REACT_APP_API_URL_FOR_SOLUTION + '/api/organisations', organization)
        .then(res => {
            dispatch({
                type: 'DETAILS_FROM_SOLUTION_ORGANIZATION_API',
                payload: res.data
            })
            dispatch({
                type: 'ORGANIZATION_LOADER',
                payload: false
            })
            NotificationManager.success("Organization details updated successfully")
        })
        .catch(err => {
            dispatch({
                type: 'ORGANIZATION_LOADER',
                payload: false
            })
            NotificationManager.error("Failed to update organization")
        })
}