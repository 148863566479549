/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import { userLogout } from '../actions/authAction';

const BACKGROUND = {
    display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', paddingTop: 0
};

const PrivateRoute = ({ component: Component, ...rest }) => {
    const auth = useSelector(state => state.authReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!auth.authLoader && !auth.loggedIn) dispatch(userLogout());
    }, [auth.authLoader, auth.loggedIn]);

    return auth.authLoader ? (
        <div className="white-bg" style={BACKGROUND}>
            <Bars color="#00A4BD" height={100} width={100} />
        </div>
    ) : (
        <Route
            {...rest}
            render={(props) =>
                auth.loggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
