/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import "../styles/ProfilePage.css"
import Navbar from './Navbar';
import { updateName } from '../actions/authAction';
import { updateUser } from '../actions/userAction';
import { NavLink, useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { ThreeDots } from 'react-loader-spinner';
import { getNotificationPreferences } from '../actions/notificationPreferenceAction';
import { getOrganizationByGetCall } from '../actions/organizationsAction';

function ProfilePage() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.userReducer);
  const [phone, setPhone] = useState(null)
  const [saveLoader, setSaveLoader] = useState(false)
  const [name, setName] = useState(null)
  const [calendarURL, setCalendarURL] = useState(null)
  const [editPhone, setEditPhone] = useState(false)
  const [editName, setEditName] = useState(false)
  const [editCalendarURL, setEditCalendarURL] = useState(false)
  const inputPhone = useRef(null);
  const inputName = useRef(null);
  const inputCalendarURL = useRef(null);
  const [activeTab, setActiveTab] = useState(useLocation.pathname);
  const organization = useSelector(state => state.organizationReducer);

  useEffect(() => {
    if (Object.keys(organization).length === 0){
      dispatch(getOrganizationByGetCall());
    }
    dispatch(getNotificationPreferences());
  }, []);
  
  useEffect(() => {
    setPhone(user.phone_number)
    setName(user.name)
    setCalendarURL(user.calendar_url)
    setSaveLoader(user.userLoader)
  }, [user])

  useEffect(() => {
    if (editPhone) {
      inputPhone.current.focus()
    }
    if (editName) {
      inputName.current.focus()
    }
    if (editCalendarURL) {
      inputCalendarURL.current.focus()
    }
  }, [editPhone, editName, editCalendarURL])

  const saveCalendarURL = () => {
    if (calendarURL !== user.calendar_url) {
      let temp = user
      temp.calendar_url = calendarURL
      dispatch(updateUser(temp))
    }
    setEditCalendarURL(false)
  }

  const savePhone = () => {
    if (phone !== user.phone_number) {
      let temp = user
      temp.phone_number = phone
      dispatch(updateUser(temp))
    }
    setEditPhone(false)
  }

  const saveName = () => {
    if (name !== user.name) {
      dispatch(updateName(name))
      let temp = user
      temp.name = name
      dispatch(updateUser(temp))
    }
    setEditName(false)
  }

  return (

    <div className='profile-wrapper'>
      <Navbar />
      <div className='d-flex align-items-start w-100 h-100 profile-content-wrapper container' style={{ flexDirection: window.innerWidth < 768 && 'column' }}>
        <div className="col-12 col-md-1 px-0" style={{ height: window.innerWidth > 768 && '100%', width: window.innerWidth > 768 && '12vw', textAlign: 'unset' }}>
          <Nav variant="tabs" className="flex-column">
            <Nav.Item>
              <Nav.Link
                as={NavLink}
                to="/profile"
                className={`link ${activeTab === '/profile' ? 'active' : ''}`}
                onClick={() => setActiveTab('/profile')}
              >
                Profile
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={NavLink}
                to="/organization"
                className={`link ${activeTab === '/organization' ? 'active' : ''}`}
                onClick={() => setActiveTab('/organization')}
              >
                Organization
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={NavLink}
                to="/notification"
                className={`link ${activeTab === '/notification' ? 'active' : ''}`}
                onClick={() => setActiveTab('/notification')}
              >
                Notification
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={NavLink}
                to="/reset-password"
                className={`link ${activeTab === '/reset-password-link' ? 'active' : ''}`}
                onClick={() => setActiveTab('/reset-password-link')}
              >
                Reset Password
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="col-12 col-md-11 px-0" style={{ marginTop: '2vw', marginLeft: '5vw' }}>
          <div className='d-flex profile-column-wrapper profile-block'>

            <div className='d-flex flex-column profile-row'>
              <div>
                <h2>Profile Settings</h2>
              </div>
              <div>
                <label>Name:</label>
              </div>
              <div>
                <label>Phone Number</label>
              </div>
              <div>
                <label>Calendar Link:</label>
              </div>
              <div>
                {saveLoader ?
                  <ThreeDots color="#a952ff" height={40} width={40} style={{ paddingLeft: '45%', paddingTop: '20%', paddingBottom: '20%' }} />
                  : <button className='btn dark-blue-button profile-page-button' onClick={e => {
                    saveCalendarURL(); savePhone(); saveName(); dispatch({
                      type: 'USER_DETAILS_LOADER',
                      payload: true
                    }); setSaveLoader(true)
                  }}>Save</button>
                }
              </div>
            </div>
            <div className='d-flex flex-column profile-row'>
              {

                <div className="input-group d-flex align-items-center justify-content-center edit-section">
                </div>

              }
              {

                <div className="input-group d-flex align-items-center justify-content-center edit-section" >
                  <input type="text" className='form-control' placeholder="Name" aria-describedby="basic-addon1" ref={inputName} value={name} onChange={e => setName(e.target.value)} />
                </div>

              }
              {

                <div className="input-group d-flex align-items-center justify-content-center edit-section">
                  <input type="phone" className="form-control" placeholder="Phone Number" aria-describedby="basic-addon1" ref={inputPhone} value={phone} onChange={e => setPhone(e.target.value)} />
                </div>
              }
              {
                <div className="input-group d-flex align-items-center justify-content-center edit-section">
                  <input type="text" className="form-control" placeholder="Calendar Link" aria-describedby="basic-addon1" ref={inputCalendarURL} value={calendarURL} onChange={e => setCalendarURL(e.target.value)} />
                </div>
              }
              {
                <div className="input-group d-flex align-items-center justify-content-center edit-section">
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfilePage