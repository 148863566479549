import axios from 'axios';
import {NotificationManager} from 'react-notifications';
import { getOrganizationOnboardStatusByGetCallSolution } from './organizationsAction';

export const getAllDocuments = (searchString) => async (dispatch) => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/documents', {
            params: {
                search: searchString
            }
        });
        dispatch(getOrganizationOnboardStatusByGetCallSolution());
        dispatch({
            type: 'ALL_DOCUMENTS',
            payload: {
                all_documents: response.data.details,
                total_documents: response.data.totalCount
            }
        });
        dispatch({
            type: 'DOCUMENTS_LOADING',
            payload: {document_loader : false}
        });

        return response.data; 
    } catch (error) {
        console.error('Error fetching documents:', error);
        throw error; 
    }
};

export const getDocumentById = (document_id) => dispatch => {
    axios
    .get(process.env.REACT_APP_API_URL + '/documents/' + document_id)
    .then(res => {
        dispatch({
            type: 'SELECTED_DOCUMENT',
            payload: {selected_document : res.data.details}
        });
    })
    .catch(err => {
        console.log(err)
        NotificationManager.error("Failed to get the document")
    })
}

export const deleteDocumentById = (document_id) => dispatch => {
    return axios
    .delete(process.env.REACT_APP_API_URL + '/documents/' + document_id)
    .then(res => {
        dispatch({
            type: 'SELECTED_DOCUMENT',
            payload: {selected_document : null}
        })
        dispatch({
            type: 'DOCUMENTS_LOADING',
            payload: {document_loader : false}
        });
        dispatch(getAllDocuments())
        NotificationManager.success("Document Deleted Successfully")

    })
    .catch(err => {
        console.log(err)
        NotificationManager.error("Failed to delete the document")
    })
}

export const pingDocumentCreated = (document_id) => {
    return axios
    .get(process.env.REACT_APP_API_URL + '/documents/' + document_id)
    .then(res => {
        console.log(res)
        return res
    })
    .catch(err => {
        console.log(err)
        return err
    })
}

export const getDownloadDocumentLink = (document_id) => dispatch => {
    NotificationManager.info("Downloading...")
    return axios
    .get(process.env.REACT_APP_API_URL + '/documents/download/' + document_id)
    .then(res => {
        return res.data.details.url
    })
    .catch(err => {
        NotificationManager.error("Failed to download the document")
    })
}

export const getUploadPresignedURL = (file) => {
    NotificationManager.info("Uploading...")
    return axios
    .post(process.env.REACT_APP_API_URL + "/documents/upload", {'fileName': file.name})
    .then(res => {
        return res
    })
    .catch(err => {
        NotificationManager.error("PDF Upload Failed")
        return false
    })
}