import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import doculensLogo from '../logos/logo-white.png';
import resetPasswordImg from '../images/Reset_password.svg';
import '../styles/ChangePassword.css';
import { validateCompletePassword } from '../validation/completePasswordValidation';
import { completePassword } from '../actions/authAction';

const ChangePassword = ({ cognitoUserForCompletePswd }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const auth = useSelector(state => state.authReducer);

    const dispatch = useDispatch();
    const history = useHistory();

    const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

    const handleSubmit = (e) => {
        e.preventDefault();
        const { errors, isValid } = validateCompletePassword({ newPassword, confirmNewPassword });
        if (!isValid) {
            setErrors(errors);
        } else {
            dispatch(completePassword(auth.cognitoUserForCompletePswd, newPassword, history));
        }
    };

    return (
        <div className="login-wrapper">
            <div className="login-form-container">
                <div className="login-form">
                    <img src={doculensLogo} alt="Doculens Logo" className="doculensLogo" />
                    <h2>Reset your password</h2>

                    <div className="form-group password-input-container">
                        <label htmlFor="newPassword">
                            New Password <span className="text-danger">*</span>
                        </label>
                        <div style={{ position: 'relative' }}>
                            <input
                                type={showNewPassword ? 'text' : 'password'}
                                className="form-control"
                                id="newPassword"
                                placeholder="Enter new password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <i
                                className={`bi ${showNewPassword ? 'bi-eye-slash' : 'bi-eye'} password-toggle-icon`}
                                onClick={toggleNewPasswordVisibility}
                            />
                        </div>
                        {errors.newPassword && <p className="error-text">{errors.newPassword}</p>}
                    </div>

                    <div className="form-group password-input-container">
                        <label htmlFor="confirmNewPassword">
                            Confirm Password <span className="text-danger">*</span>
                        </label>
                        <div style={{ position: 'relative' }}>
                            <input
                                type={showConfirmPassword ? 'text' : 'password'}
                                className="form-control"
                                id="confirmNewPassword"
                                placeholder="Confirm new password"
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                            />
                            <i
                                className={`bi ${showConfirmPassword ? 'bi-eye-slash' : 'bi-eye'} password-toggle-icon`}
                                onClick={toggleConfirmPasswordVisibility}
                            />
                        </div>
                        {errors.confirmNewPassword && <p className="error-text">{errors.confirmNewPassword}</p>}
                    </div>

                    <button type="submit" onClick={handleSubmit} className="btn btn-primary login-button">
                        Change Password
                    </button>
                </div>

                <div className="login-image">
                    <img src={resetPasswordImg} alt="Change password illustration" className="loginPageImg" />
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
