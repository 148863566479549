import axios from 'axios';
import {NotificationManager} from 'react-notifications';

export const appendAnalysis = (id, dict) => dispatch => {
    axios.post(process.env.REACT_APP_API_URL + '/tracking', {
        'id': id,
        'analysis': dict,
        'event': 'APPEND_ANALYSIS'
    })
}

export const pingAnalysis = (id) => dispatch => {
    axios.post(process.env.REACT_APP_API_URL + '/tracking/', {
        'id': id,
        'event': 'PING_VIEW'
    })
}

export const downloadedDocument = (id) => dispatch => {
    axios.post(process.env.REACT_APP_API_URL + '/tracking/', {
        'id': id,
        'event': 'DOWNLOADED_DOCUMENT'
    })
}

export const createAnalysis = (id, documentId, organizationId, emailId, timestamp, id7ts, noStart, userId) => dispatch => {
    axios
    .post(process.env.REACT_APP_API_URL + '/tracking/', {
        'id': id,
        'document_id': documentId,
        'organization_id': organizationId,
        'email_id': emailId,
        'start_time': timestamp,
        'noStart': noStart,
        'id7ts': id7ts,
        'user_id': userId,
        'event': 'CREATE_VIEW'
    })
}