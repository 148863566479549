const notificationPreferencesReducer = (state = {}, action) => {
    switch (action.type) {
        case 'DETAILS_FROM_NOTIFICATION_PREFERENCES_API':
            return {
                ...state,
                ...action.payload
            }
        case 'SAVING_NOTIFICATION_PREFERENCES':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default notificationPreferencesReducer;