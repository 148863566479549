/* eslint-disable no-unused-vars */
import Validator from 'validator';
import maxLengths from './validation/length-validation.json';
export function b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
    }));
}

export function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}
export const validURL = (str) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
        + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
        + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
        + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
        + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
        + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
};
const validateUserFirstName = (errors, data, errorKey = 'firstName', dataKey = 'firstName') => {
    const { min, max } = maxLengths.userFullName;
    if (!Validator.isLength(data[dataKey] || '', { min, max })) {
        errors[errorKey] = `Name must be of ${min} to ${max} characters`;
    }

    if (Validator.isEmpty(data[dataKey] || '')) {
        errors[errorKey] = 'Name is required';
    }
};
const validateUserEmail = (errors, data, errorKey = 'signupEmail', dataKey = 'signupEmail') => {
    if (!Validator.isEmail(data[dataKey] || '')) {
        errors[errorKey] = 'Email is invalid';
    }
    if (Validator.isEmpty(data[dataKey] || '')) {
        errors[errorKey] = 'Email is required';
    }
};
const validateAcceptedTnC = (errors, data, errorKey = 'checkbox', dataKey = 'checkbox') => {
    if (data[dataKey] === false) {
        errors[errorKey] = 'Please accept Terms of Service and Privacy Policy';
    }
};

// ACCOUNT CREDENTIALS
const specialChars = "<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=";
const checkForSpecialChars = (string) => {
    for (let i = 0; i < specialChars.length; i++) {
        if (string.indexOf(specialChars[i]) > -1) {
            return true;
        }
    }
    return false;
};

const checkForNumbers = (string) => {
    const re = new RegExp(/\S*[0-9]+\S*/g);
    return re.test(string);
};

const checkSmallCase = (string) => {
    const re = new RegExp(/\S*[a-z]+\S*/g);
    return re.test(string);
};

const checkUpperCase = (string) => {
    const re = new RegExp(/\S*[A-Z]+\S*/g);
    return re.test(string);
};

// GENERIC
const validateRequired = (errors, data, errorKey = 'errorKey', dataKey = 'dataKey', message = 'This field is required') => {
    if (Validator.isEmpty(data[dataKey] || '')) {
        errors[errorKey] = message;
    }
};
const validateForgotPasswordCode = (errors, data, errorKey = 'fpSubmitCode', dataKey = 'code') => {
    validateRequired(errors, data, errorKey, dataKey, 'Code is required');
};
const validatePasswordFormation = (errors, data, errorKey = 'signupPassword', dataKey = 'signupPassword') => {
    const { min, max } = maxLengths.password;
    if (!checkForSpecialChars(data[dataKey])) {
        errors[errorKey] = 'Password must contain at least one special character';
    }

    if (!checkForNumbers(data[dataKey])) {
        errors[errorKey] = 'Password must contain at least one digit from 0 to 9';
    }

    if (!checkSmallCase(data[dataKey])) {
        errors[errorKey] = 'Password must contain at least one lowercase alphabet from a to z';
    }

    if (!checkUpperCase(data[dataKey])) {
        errors[errorKey] = 'Password must contain at least one upper case alphabet from A to Z';
    }

    if (!Validator.isLength(data[dataKey] || '', { min, max })) {
        errors[errorKey] = `Password must be of ${min} to ${max} characters`;
    }

    if (Validator.isEmpty(data[dataKey] || '')) {
        errors[errorKey] = 'Password is required';
    }
};
export const validatePasswordFormationForForgotPassword = (errors, data, errorKey = 'fpSubmitPassword', dataKey = 'new_password') => {
    const { min, max } = maxLengths.password;


    if (!checkForSpecialChars(data[dataKey])) {
        errors[errorKey] = 'Password must contain at least one special character';
    }

    if (!checkForNumbers(data[dataKey])) {
        errors[errorKey] = 'Password must contain at least one digit from 0 to 9';
    }

    if (!checkSmallCase(data[dataKey])) {
        errors[errorKey] = 'Password must contain at least one lowercase alphabet from a to z';
    }

    if (!checkUpperCase(data[dataKey])) {
        errors[errorKey] = 'Password must contain at least one upper case alphabet from A to Z';
    }

    if (!Validator.isLength(data[dataKey] || '', { min, max })) {
        errors[errorKey] = `Password must be of ${min} to ${max} characters`;
    }

    if (Validator.isEmpty(data[dataKey] || '')) {
        errors[errorKey] = 'Password is required';
    }
};


export const validateConfirmPasswordForForgotPassword = (errors, data, errorKey = 'confirmPassword', dataKey = 'confirmPassword', dataKeyPassword = 'new_password') => {
    if (!Validator.equals(data[dataKeyPassword] || '', data[dataKey] || '')) {
        errors[errorKey] = 'Confirm password does not match';
    }
};
const validateConfirmPassword = (errors, data, errorKey = 'signupConfirmPassword', dataKey = 'signupConfirmPassword', dataKeyPassword = 'signupPassword') => {
    if (!Validator.equals(data[dataKeyPassword] || '', data[dataKey] || '')) {
        errors[errorKey] = 'Confirm password does not match';
    }
    if (Validator.isEmpty(data[dataKey] || '')) {
        errors[errorKey] = 'Confirm password is required';
    }
};
export {
    validatePasswordFormation,
    validateConfirmPassword,
    validateAcceptedTnC,
    validateUserFirstName,
    validateUserEmail,
    validateForgotPasswordCode,
    validateRequired,
    checkForSpecialChars,
    checkForNumbers,
    checkSmallCase,
    checkUpperCase,
};
