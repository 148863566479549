/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { getDownloadDocumentLink } from '../actions/documentAction';
import { useDispatch, useSelector } from "react-redux";
import { getUserInfoWithoutAuth } from '../actions/userAction';
import { appendAnalysis, pingAnalysis, downloadedDocument, createAnalysis } from '../actions/trackingAction';
import { createViewTracker, updateViewTracker, getIpDetails } from '../actions/viewTrackerAction';
import { v4 as uuidv4 } from 'uuid';
import '../styles/PdfReader.css'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Rings } from 'react-loader-spinner'
import { NotificationManager } from 'react-notifications';
import { OverlayTrigger, Popover } from 'react-bootstrap'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { b64DecodeUnicode } from '../utils';
import { useCookies } from 'react-cookie';
import { Tooltip } from 'antd';


const PdfReader = () => {
    const [emailSource, setEmailSource] = useState(null)
    const [viewTrackerId, setViewTrackerId] = useState(null)
    const [params7ts, setParams7ts] = useState(null);
    const { documentDetails } = useParams();
    const [documentId, setDocumentId] = useState(null);
    const dispatch = useDispatch();
    const [fileName, setFileName] = useState(null)
    const [fileUrl, setFileUrl] = useState(null)
    const [showPdf, setShowPdf] = useState(false)
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [leftDisabled, setLeftDisabled] = useState(true)
    const [rightDisabled, setRightDisabled] = useState(false)
    const [email, setEmail] = useState(null)
    const [user, setUser] = useState(null)
    const [userId, setUserId] = useState(null)
    const [uuid, setUuid] = useState(uuidv4());
    const [intervalId, setIntervalId] = useState(null)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [scale, setScale] = useState(1)
    const [selectedThumbnail, setSelectedThumbnail] = useState(1)
    const search = useLocation().search;
    const [cookies, setCookie] = useCookies(['emailId']);
    const [isBot, setIsBot] = useState(false)
    const [id7ts, setId7ts] = useState(null)
    const userAgent = window.navigator.userAgent
    const organization = useSelector(state => state.organizationReducer);



    useEffect(() => {
        if (window.innerWidth > 1400) {
            setScale(1.6)
        } else if (window.innerWidth > 1200) {
            setScale(1.4)
        } else if (window.innerWidth > 992) {
            setScale(1.2)
        } else if (window.innerWidth > 768) {
            setScale(1.0)
        }
    }, [])

    // useEffect(() => {
    //     if (userAgent.includes('bing')) {
    //         setIsBot(true)
    //     }
    // }, userAgent)

    useEffect(() => {
        if (documentDetails !== null && documentDetails !== undefined) {
            let temp = documentDetails;
            if (documentDetails.length % 4 === 2) {
                temp = documentDetails + "==";
            } else if (documentDetails.length % 4 === 3) {
                temp = documentDetails + "=";
            }
            var details = b64DecodeUnicode(temp).split('/')
            dispatch(getDownloadDocumentLink(temp))
                .then(res => { setFileUrl(res) })
            setUserId(details[0])
            setDocumentId(details[1])
            setFileName(details[2])
            dispatch(getIpDetails()).then(res => { dispatch(createViewTracker(details[1], res)).then(res => { setViewTrackerId(res) }) })
        }
    }, [documentDetails])

    useEffect(() => {
        if (documentDetails.length > 1 && documentDetails[1] === 't1') {
            let email;
            try {
                email = JSON.parse(b64DecodeUnicode(documentDetails[2])).email
                setId7ts(JSON.parse(b64DecodeUnicode(documentDetails[2])).id7ts)
                setParams7ts(JSON.parse(b64DecodeUnicode(documentDetails[2])))
            } catch (err) {
                console.log("not a base64 encoded email address")
            }
            if (validateEmail(email)) { setEmail(email); setEmailSource('PATH_VARIABLE') }
        } else {

            let email = new URLSearchParams(search).get('email');
            console.log('email', email);

            setId7ts(new URLSearchParams(search).get('id7ts') || null);
            if (email != null && email != undefined) {
                try {
                    email = b64DecodeUnicode(email)
                } catch (err) {
                    console.log("not a base64 encoded email address")
                }
                setEmailSource('LINK')
            }
            if (email === null || email === undefined) {
                email = cookies.emailId
                if (email != null && email != undefined) {
                    setEmailSource('COOKIE')
                }
            }
            if (documentId !== null && documentId !== undefined) {
                if (email !== null && email !== undefined && email.trim() !== '') {
                    if (validateEmail(email)) {
                        handleSubmit({ target: { email: { value: email } } })
                    }
                }
            }
        }
    }, [search, documentId, cookies, documentDetails])

    const finish_viewing = () => {
        var start = new Date().getTime();
        const xhr = new XMLHttpRequest();
        xhr.open("POST", process.env.REACT_APP_API_URL + '/tracking', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify({ 'id': uuid, 'end_time': start, event: 'FINISH_VIEW' }));
        while (new Date().getTime() < start + 800);
    }

    const tab_change = (e) => {
        let timestamp = new Date().getTime()
        let dict_to_append = { 'page_number': pageNumber }
        if (e.target.visibilityState === 'visible') {
            dict_to_append['start_time'] = timestamp
        } else {
            dict_to_append['end_time'] = timestamp
        }
        dispatch(appendAnalysis(uuid, dict_to_append))
    }

    const ping = () => {
        dispatch(pingAnalysis(uuid))
    }

    const scaleUp = () => {
        if (scale >= 1.6) {
            return
        }
        setScale(scale + 0.2)
    }

    const scaleDown = () => {
        if (scale <= 0.6) {
            return
        }
        setScale(scale - 0.2)
    }

    useEffect(() => {
        return () => {
            window.removeEventListener('visibilitychange', tab_change)
            window.removeEventListener('pagehide', finish_viewing)
            window.removeEventListener('beforeunload', finish_viewing)
            clearInterval(intervalId)
        }
    }, [])

    const onDocumentLoadSuccess = (pdf) => {
        let { numPages } = pdf
        setNumPages(numPages);
        if (numPages === 1) {
            setLeftDisabled(true)
            setRightDisabled(true)
        }
    }

    const handlePageChange = (currentPage, currentTimestamp) => {
        if (currentPage === 1) {
            setLeftDisabled(true)
            setRightDisabled(false)
        } else if (currentPage === numPages) {
            setRightDisabled(true)
            setLeftDisabled(false)
        } else {
            setLeftDisabled(false)
            setRightDisabled(false)
        }
        dispatch(appendAnalysis(uuid, {
            'page_number': currentPage,
            'start_time': currentTimestamp
        }))
    }

    const pageDown = () => {
        let currentTimestamp = new Date().getTime()
        let currentPage = pageNumber
        if (currentPage > 1) {
            currentPage = pageNumber - 1
            setPageNumber(currentPage)
        }
        handlePageChange(currentPage, currentTimestamp)
    }

    const pageUp = () => {
        let currentTimestamp = new Date().getTime()
        let currentPage = pageNumber
        if (currentPage < numPages) {
            currentPage = pageNumber + 1
            setPageNumber(currentPage)
        }
        handlePageChange(currentPage, currentTimestamp)
    }

    const validateEmail = (email) => {
        if (email.trim() == '') {
            return false
        }
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const validateEmailAndSetError = (e) => {
        if (!validateEmail(e.target.value)) {
            setError("Enter a valid email address")
            setSuccess(null)
            return
        }
        setSuccess("This email address is valid")
        setError(null)
    }

    const handleSubmit = (e) => {
        if (e.target.email.value === null || e.target.email.value === undefined || e.target.email.value.trim() === '') {
            setError("Enter a valid email address")
            setSuccess(null)
            e.preventDefault()
            return
        }
        let timestamp = new Date().getTime()
        if (error !== null) {
            e.preventDefault()
            return
        }
        setEmail(e.target.email.value)
        setCookie('emailId', e.target.email.value, { path: '/', maxAge: 315360000 })
        setShowPdf(true)
        if (document.visibilityState === 'visible') {
            dispatch(createAnalysis(uuid, documentId, user?.organization?.id, e.target.email.value, timestamp, id7ts, false, userId))
        } else {
            dispatch(createAnalysis(uuid, documentId, user?.organization?.id, e.target.email.value, timestamp, id7ts, true, userId))
        }
        window.addEventListener('visibilitychange', tab_change)
        window.addEventListener('pagehide', finish_viewing)
        window.addEventListener('beforeunload', finish_viewing)
        let intervalId = setInterval(ping, 10000)
        setIntervalId(intervalId)
        if (emailSource == null) {
            setEmailSource('TYPED')
        }
    }

    useEffect(() => {
        if (email !== null && emailSource !== null && viewTrackerId !== null && email !== undefined && emailSource !== undefined && viewTrackerId !== undefined) {
            dispatch(updateViewTracker(viewTrackerId, email, emailSource, params7ts))
        }
    }, [email, emailSource, viewTrackerId])

    useEffect(() => {
        if (documentId !== null && documentId !== undefined) {
            dispatch(getUserInfoWithoutAuth(documentId))
                .then(res => setUser(res))
        }
    }, [documentId])

    const downloadPdf = () => {
        if (!email) {
            return
        }
        const element = document.createElement("a");
        element.href = fileUrl;
        element.target = "_blank"
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
        dispatch(downloadedDocument(uuid))
    }

    const sharePdf = () => {
        navigator.clipboard.writeText(process.env.REACT_APP_BASE_URL + "/document/" + documentDetails)
        NotificationManager.success("Link copied to clipboard")
    }

    const changePageThroughThumbnail = (newPageNumber) => {
        let currentTimestamp = new Date().getTime()
        if (pageNumber != newPageNumber) {
            setSelectedThumbnail(newPageNumber)
            setPageNumber(newPageNumber)
            handlePageChange(newPageNumber, currentTimestamp)
        }
    }

    const renderThumbnails = () => {
        let result = [];
        for (var i = 0; i < numPages; i++) {
            let temp = i
            result.push(
                <div onClick={e => changePageThroughThumbnail(temp + 1)}>
                    <img className={temp + 1 === selectedThumbnail ? 'pdf-reader-thumbnail pdf-reader-thumbnail-selected' : 'pdf-reader-thumbnail'} src={user !== undefined && user.doc_logo_key ? 'https://doculens-documents-thumbnail-' + process.env.REACT_APP_AWS_ACCOUNT_ID + '-' + process.env.REACT_APP_STAGE + '.s3.amazonaws.com/' + encodeURIComponent(user.doc_logo_key.replace('.pdf', '') + '_' + i + '.jpg') : null} />
                    <p className='text-center mt-1'>{i + 1}</p>
                </div>
            )
        }
        return result
    }
    const copyEmailToClipboard = () => {
        if (user?.email_id) {
            navigator.clipboard.writeText(user.email_id)
                .then(() => {
                    NotificationManager.success('Email copied to clipboard!');
                })
                .catch(err => {
                    NotificationManager.error('Failed to copy email.');
                });
        }
    };

    const popover = (
        <Popover id="popover-basic">
            <Popover.Body >
                <div className='d-flex justify-content-center'>
                    <div className='popover-user-image-box d-flex justify-content-center'>
                        <i className="bi bi-person-circle"></i>
                    </div>
                    <div className='m-1 p-1'>
                        <p className='popover-name'>{user && user.name ? user.name : null}</p>
                        <p className='popover-email'>{user && user.email_id ? user.email_id : null}</p>
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    {
                        user !== null && user !== undefined && user.calendar_url ?
                            <a href={(user || {}).calendar_url} style={{ color: 'inherit', textDecoration: 'none' }}>
                                <button className="popover-button"><i className="bi bi-calendar-check-fill"></i>&nbsp;&nbsp;Let's Meet</button>
                            </a>
                            :
                            null
                    }
                    {
                        user !== null && user !== undefined && user.email_id !== null ?
                            <a href={"mailto:" + (user || {}).email_id} style={{ color: 'inherit', textDecoration: 'none' }}>
                                <button className="popover-button"><i className="bi bi-envelope-fill"></i>&nbsp;&nbsp;Email Me</button>
                            </a>
                            :
                            null
                    }
                </div>
            </Popover.Body>
        </Popover>
    );
    return (
        <React.Fragment>
            <nav className="navbar z-top" style={{ background: '#303133', borderBottom: '#a952ff solid 1px' }}>
                <div className="container">
                    <img src={user && user.organization.logo_key ? `https://doculens-organization-logos-879924793392-${process.env.REACT_APP_STAGE}.s3.amazonaws.com/${user.organization.logo_key}` : null} alt="user-logo" width="50" height="50" />
                    <div>
                        <button className="pdf-reader-button d-none d-lg-inline d-xl-inline d-xxl-inline" onClick={sharePdf}>Share</button>
                        <button className="pdf-reader-button d-inline d-lg-none d-xl-none d-xxl-none" onClick={sharePdf}><i className="bi bi-share-fill"></i></button>
                        {email !== null ? <button className="pdf-reader-button d-none d-lg-inline d-xl-inline d-xxl-inline" onClick={downloadPdf}>Download</button> : null}
                        {email !== null ? <button className="pdf-reader-button d-inline d-lg-none d-xl-none d-xxl-none" onClick={downloadPdf}><i className="bi bi-download"></i></button> : null}
                        {email !== null ?
                            <Tooltip title={`Click to copy email ${user?.email_id}`} >
                                {/* <OverlayTrigger placement="bottom" overlay={popover}> */}
                                <i className="bi bi-person-circle pdf-reader-button" style={{ cursor: 'pointer' }} onClick={copyEmailToClipboard} />
                                {/* </OverlayTrigger> */}
                            </Tooltip>
                            :
                            null
                        }
                    </div>
                </div>
            </nav>
            <div className='container-fluid grey-background full-page' style={{ backgroundColor: '#303133' }}>
                <div className='row justify-content-center'>
                    <div className='d-flex justify-content-center overflow-auto full-length' style={{ backgroundColor: '#303133', color: '#ffffff' }}>
                        {
                            user !== null && user !== undefined ?
                                showPdf ?
                                    fileUrl ?
                                        <Document noData={''} file={fileUrl} onLoadSuccess={onDocumentLoadSuccess} externalLinkTarget="_blank" loading={<Rings color="#52a1ff" height={80} width={80} />} className='top-margin-large' style={{ backgroundColor: '#303133' }}>
                                            <Page pageNumber={pageNumber} scale={scale} />
                                            <div className="page-controls">
                                                <button type="button" className='d-none d-lg-inline d-xl-inline' onClick={scaleDown} disabled={scale <= 0.6}><i className="bi bi-zoom-out"></i></button>
                                                <button type="button" disabled={leftDisabled} onClick={pageDown}><i className="bi bi-arrow-left" style={{ color: '#ffffff' }}></i></button>
                                                <span>{pageNumber + " of " + numPages}</span>
                                                <button type="button" disabled={rightDisabled} onClick={pageUp}><i className="bi bi-arrow-right" style={{ color: '#ffffff' }}></i></button>
                                                <button type="button" className='d-none d-lg-inline d-xl-inline' onClick={scaleUp} disabled={scale >= 1.6}><i className="bi bi-zoom-in"></i></button>
                                            </div>
                                        </Document>
                                        :
                                        <div className='top-margin-large'><Rings color="#52a1ff" height={80} width={80} /></div>
                                    :
                                    <div className='p-5 top-margin-large' style={{ height: 'fit-content', backgroundColor: '#303133', border: '1px solid #a952ff', borderRadius: '0.8vw' }}>
                                        <h5 className='p-2 enter-email-heading'>{(user || {}).name} shared this document with you.</h5>
                                        <form onSubmit={handleSubmit}>
                                            <label className='label'>Please enter your email address to continue *</label>
                                            <input type="email" className="form-control form-control-lg mt-2 mb-3" placeholder="Email Id" name="email" onChange={validateEmailAndSetError} />
                                            <button className='login-button m-0 pdf-reader-button' type='submit'>View PDF</button>
                                            {
                                                error ?
                                                    <p style={{ color: 'red', fontSize: '0.9rem' }}>{error}</p> :
                                                    null
                                            }
                                            {
                                                success ?
                                                    <p style={{ color: 'green', fontSize: '0.9rem' }}>{success}</p> :
                                                    null
                                            }
                                        </form>
                                    </div>
                                :
                                <div className='top-margin-large'><Rings color="#52a1ff" height={80} width={80} /></div>
                        }
                    </div>
                    {
                        showPdf ?
                            <div className='sidebar overflow-auto full-length d-none d-lg-block d-xl-block'>
                                <div>{renderThumbnails()}</div>
                            </div>
                            :
                            null
                    }
                </div>
            </div>

        </React.Fragment>
    )
}

export default PdfReader;