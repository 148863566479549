/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import Views from './components/Views';
import Documents from './components/Documents';
import PdfReader from "./components/PdfReader";
import './App.css';
import ProfilePage from "./components/ProfilePage";
import OrganizationPage from './components/OrganizationPage';
import NotificationPage from "./components/NotificationPage";
import { setCurrentUser } from "./actions/authAction";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Plan from "./components/Plan";
import LoginPage from "./components/LoginPage";
import SignupPage from "./components/SignupPage";
import PrivateRoute from "./route/PrivateRoute";
import ForgotPassword from "./components/ForgotPassword";
import ChangePassword from "./components/ChangePassword";
import ResetPasswordLink from "./components/ResetPassword";

function AppContent() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentUser());
  }, []);

  return (
    <Switch>
      <Route path="/views/:documentId">
        <Views />
      </Route>
      <Route path="/document/:documentDetails">
        <PdfReader />
      </Route>
      <PrivateRoute
        exact
        path="/profile"
        component={ProfilePage}
      />
      <PrivateRoute
        exact
        path="/organization"
        component={OrganizationPage}
      />
      <PrivateRoute
        exact
        path="/notification"
        component={NotificationPage}
      />
      <PrivateRoute
        exact
        path="/plan"
        component={Plan}
      />

      <PrivateRoute
        exact
        path="/documents"
        component={Documents}
      />
      {/* <PrivateRoute
        exact
        path="/documents?signUp"
        component={Documents}
      /> */}
      <Route
        exact
        path="/reset-password"
        component={ForgotPassword}
      />
      {/* <Route path="/register">
        <SignupPage />
      </Route> */}

      <Route path="/complete-new-password">
        <ChangePassword />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/">
        <LoginPage />
      </Route>
      
    </Switch>
  );
}

export default function App() {
  return (
    <Router>
      <AppContent />
      <NotificationContainer />
    </Router>
  );
}
