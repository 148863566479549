import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Switch, Tooltip, InputNumber } from 'antd';
import Navbar from './Navbar';
import { updateNotificationPreferences, getNotificationPreferences } from '../actions/notificationPreferenceAction';
import { NavLink, useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { ThreeDots } from 'react-loader-spinner';
import { NotificationManager } from 'react-notifications';

function NotificationPage() {
    const dispatch = useDispatch();
    const notifications = useSelector(state => state.notificationPreferencesReducer);
    const [documentViewNotification, setDocumentViewNotification] = useState(false);
    const [documentDownloadNotification, setDocumentDownloadNotification] = useState(false);
    const [minSecondsViewedNotification, setMinSecondsViewedNotification] = useState(false);
    const [notificationPreferenceId, setNotificationPreferenceId] = useState();
    const [activeTab, setActiveTab] = useState(useLocation.pathname);
    const [saving, setSaving] = useState(false);
    const [initialNotifications, setInitialNotifications] = useState({
        view: false,
        download: false,
        min_seconds_viewed: 0,
        id: null
    });

    useEffect(() => {
        if (Object.keys(notifications).length === 0) {
            dispatch(getNotificationPreferences());
        }
        setDocumentViewNotification(notifications.view);
        setDocumentDownloadNotification(notifications.download);
        setMinSecondsViewedNotification(notifications.min_seconds_viewed);
        setNotificationPreferenceId(notifications.id);
        setInitialNotifications({
            view: notifications.view,
            download: notifications.download,
            min_seconds_viewed: notifications.min_seconds_viewed,
            id: notifications.id
        })
        setSaving(notifications.preference_loader || false);
    }, [notifications])


    const saveNotifications = () => {

        if (notifications.view !== documentViewNotification || notifications.download !== documentDownloadNotification || notifications.min_seconds_viewed !== minSecondsViewedNotification) {
            dispatch({
                type: 'SAVING_NOTIFICATION_PREFERENCES',
                payload: { preference_loader: true }
            })
            let temp = notifications;
            temp.view = documentViewNotification;
            temp.download = documentDownloadNotification;
            temp.min_seconds_viewed = minSecondsViewedNotification;
            temp.id = notificationPreferenceId;
            NotificationManager.info("Updating notification preferences...")
            dispatch(updateNotificationPreferences(temp))
        }
    }
    const blockInvalidChar = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    const hasChanges = (
        initialNotifications.view !== documentViewNotification ||
        initialNotifications.download !== documentDownloadNotification ||
        initialNotifications.min_seconds_viewed !== minSecondsViewedNotification
    );
    return (

        <div className=' profile-wrapper'>
            <Navbar />
            <div className='d-flex align-items-start w-100 h-100 profile-content-wrapper container' style={{ flexDirection: window.innerWidth < 768 && 'column' }}>
                <div className="col-12 col-md-1 px-0" style={{ height: window.innerWidth > 768 && '100%', width: window.innerWidth > 768 && '12vw', textAlign: 'unset' }}>
                    <Nav variant="tabs" className="flex-column">
                        <Nav.Item>
                            <Nav.Link
                                as={NavLink}
                                to="/profile"
                                className={`link ${activeTab === '/profile' ? 'active' : ''}`}
                                onClick={() => setActiveTab('/profile')}
                            >
                                Profile
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                as={NavLink}
                                to="/organization"
                                className={`link ${activeTab === '/organization' ? 'active' : ''}`}
                                onClick={() => setActiveTab('/organization')}
                            >
                                Organization
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                as={NavLink}
                                to="/notification"
                                className={`link ${activeTab === '/notification' ? 'active' : ''}`}
                                onClick={() => setActiveTab('/notification')}
                            >
                                Notification
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                as={NavLink}
                                to="/reset-password"
                                className={`link ${activeTab === '/reset-password-link' ? 'active' : ''}`}
                                onClick={() => setActiveTab('/reset-password-link')}
                            >
                                Reset Password
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <div className="col-12 col-md-11 px-0" style={{ marginTop: '2vw', marginLeft: '5vw' }}>
                    <div className='profile-block'>
                        <div className='d-flex profile-row ' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <div>
                                <h2>Profile Settings</h2>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', justifyContent: 'space-between' }}>
                                <div>
                                    <Tooltip title="An email notification will be sent if someone views the document">
                                        <label>Document View Notification</label>
                                    </Tooltip>
                                    :
                                </div>
                                <Switch onChange={(e) => setDocumentViewNotification(e)} value={documentViewNotification} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', justifyContent: 'space-between' }}>
                                <div>
                                    Notify only if the view is for
                                    <InputNumber
                                        min={0}
                                        max={600}
                                        value={minSecondsViewedNotification}
                                        onKeyDown={blockInvalidChar}
                                        onChange={(e) =>
                                            setMinSecondsViewedNotification(e)}
                                        style={{ marginLeft: '10px', marginRight: '10px', width: '13%' }}
                                        type="number"
                                        size="small"
                                    />
                                    seconds or more.
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '20px' }}>
                                <div>
                                    <Tooltip title="An email notification will be sent if someone downloads the document">
                                        <label>Document Download Notification</label>
                                    </Tooltip>
                                    :
                                </div>
                                <Switch onChange={(e) => setDocumentDownloadNotification(e)} value={documentDownloadNotification} />
                            </div>
                        </div>
                        <div>
                            {
                                saving ?
                                    <ThreeDots color="#a952ff" height={40} width={40} style={{ paddingLeft: '45%', paddingTop: '20%', paddingBottom: '20%' }} />
                                    :
                                    <button className='btn dark-blue-button profile-page-button' onClick={e => { saveNotifications() }} style={{ cursor: !hasChanges && 'not-allowed' }}>Save</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationPage