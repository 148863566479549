import axios from 'axios';
import { NotificationManager } from 'react-notifications';

export const getPlans = (currency) => dispatch => {
    axios
        .get(`${process.env.REACT_APP_API_URL_FOR_SOLUTION}/api/plans/Doculens/v4/${currency}`)
        .then(res => {
            dispatch({
                type: 'DOCULENS_PLANS',
                payload: res.data
            });
            dispatch({
                type: 'DOCULENS_PLANS_LOADER',
                payload: false
            });
            dispatch({ type: 'SAVE_PLAN_SUCCESS', payload: true });
            return res.data;
        })
        .catch(err => {
            console.log("Failed to get plans");
            dispatch({ type: 'SAVE_PLAN_FAILURE', payload: false });
            NotificationManager.error("Failed to get plans")
        })
}

export const selectAndSavePlan = (plan) => (dispatch) => axios
    .post(`${process.env.REACT_APP_API_URL_FOR_SOLUTION}/api/plans/Doculens`, plan)
    .then((res) => {
        dispatch({
            type: 'SELECT_PLAN',
            payload: res.data
        });
        return res;
        // history.push("/add-leads");
        //    dispatch(setCurrentUser());
    })
    .catch(() => 'FAILURE');

export const getActivePlan = () => (dispatch) => axios
    .get(`${process.env.REACT_APP_API_URL_FOR_SOLUTION}/api/plans/Doculens/user/active`)
    .then((res) => {
        dispatch({
            type: 'ACTIVE_PLAN',
            payload: res.data
        });
        return res;
        // history.push("/add-leads");
        //    dispatch(setCurrentUser());
    })
    .catch(() => 'FAILURE');