const documentReducer = (state = {}, action) => {
    switch (action.type) {
        case 'ALL_DOCUMENTS':
            return {
                ...state,
                ...action.payload
            }
        case 'SELECTED_DOCUMENT':
            return {
                ...state,
                ...action.payload
            }
        case 'DOCUMENTS_LOADING':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default documentReducer;