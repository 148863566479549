import isEmpty from "./is-empty";
import { validatePasswordFormation, validateUserEmail } from "../utils";

export const validateLogin = data => {
    let errors = {};

    data.loginEmail = !isEmpty(data.loginEmail) ? data.loginEmail : "";
    data.loginPassword = !isEmpty(data.loginPassword) ? data.loginPassword : "";

    validateUserEmail(errors, data, 'loginEmail', 'loginEmail');
    validatePasswordFormation(errors, data, 'loginPassword', 'loginPassword');

    return {
        errors,
        isValid: isEmpty(errors)
    };
};
