const initialState = {
  loggedIn: !!localStorage.getItem(
    'CognitoIdentityServiceProvider.' + process.env.REACT_APP_COGNITO_CLIENT_ID + '.LastAuthUser'
  ),
  authLoading: true,
  errors: null,
  isAuthenticated: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SIGNIN':
      return {
        ...state,
        loggedIn: true,
        authLoading: false,
        isAuthenticated: false
      };
    case 'SIGNOUT':
      return {
        ...state,
        loggedIn: false,
        authLoading: false,
        isAuthenticated: false
      };
    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.payload,
      };
    case 'AUTH_LOADING':
      return {
        ...state,
        authLoader: action.payload,
      };
    case 'IS_AUTHENTICATED':
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case 'COMPLETE_PASSWORD':
      return {
        ...state,
        cognitoUserForCompletePswd: action.payload
      };
    case 'EMAIL_FOR_RESET_PASSWORD':
      return {
        ...state,
        usernameForPasswordReset: action.payload
      }
      
    default:
      return state;
  }
};

export default authReducer;