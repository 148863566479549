import React, { useState, useCallback, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useDropzone } from 'react-dropzone'
import Modal from 'react-modal';
import '../styles/UploadModal.css'
import { getProfileUploadPresignedURL } from '../actions/userAction';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
  },
};

const ImageUpload = (props) => {
  const [upImg, setUpImg] = useState();
  const [error, setError] = useState(null)
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles[0].size > 10000000) {
      setError("Size of image should be less than 10MB")
    }
    const reader = new FileReader();
    reader.addEventListener('load', () => setUpImg(reader.result));
    reader.readAsDataURL(acceptedFiles[0]);
  }, [])

  const onDropRejected = useCallback(rejectedFiles => {
    if (rejectedFiles.length > 1) {
      setError("Only one file is accepted")
    }
    else if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
      setError("Only image file is accepted")
    } else {
      setError("File cannot be uploaded")
    }
  })

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, accept: 'image/jpeg, image/png', onDropRejected })

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const uploadCrop = () => {
    let crop = completedCrop
    var canvas = document.createElement('canvas');
    const image = imgRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;
    ctx.globalAlpha = 0;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    canvas.toBlob(
      (blob) => {
        let file = new File([blob], (+new Date() + '.png'), { type: 'image/png' });
        getProfileUploadPresignedURL(file)
        closeModal()
      },
      'image/png',
      1
    );
  }

  const closeModal = () => {
    setCompletedCrop(null)
    imgRef.current = null
    setUpImg(null);
    props.closeModal()
  }

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        style={customStyles}
        onRequestClose={closeModal}
        ariaHideApp={false}
      >
        {
          upImg ?
            <div className='image-crop-box'>
              <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
              />
              {
                completedCrop?.width && completedCrop?.height ?
                  <button onClick={uploadCrop} className='red-button-document mt-4' style={{ alignSelf: 'center' }}>Upload</button> : null
              }
            </div> :
            <div {...getRootProps()} className='col-6 green-color-background outer-box' style={{ maxHeight: '100%' }}>
              <div className='inner-box' style={{ padding: '3vw' }}>
                <input {...getInputProps()} className='inner-box' />
                <div className='upload-icon p-2'>
                  <i className="bi bi-person-bounding-box"></i>
                </div>
                {
                  isDragActive ?
                    <p className='upload-text'>Drop the image here...</p> :
                    <p className='upload-text'>Drag and drop image file here, or click to select file</p>
                }
                {
                  error ?
                    <p className='upload-error-text'>{error}</p> :
                    null
                }
              </div>
            </div>

        }
      </Modal>
    </div>

  );
}

export default ImageUpload;